body {
  background: #fff !important;
  outline: none !important;
}

body.modal-open {
  overflow: hidden !important;
}

*{
  outline:none;
}

@font-face {
  font-family: 'QanelasSoft-Regular';
  src: url("../fonts/QanelasSoft-Regular.ttf");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'QanelasSoft-Bold';
  src: url("../fonts/QanelasSoft-Bold.ttf");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'QanelasSoft-Medium';
  src: url("../fonts/QanelasSoft-Medium.ttf");
  font-weight: normal;
  font-style: normal;
}

/**************Font Text Color*******************/

/************Font family style****************/

/***************Margin style***********************/

/********** Display style************/

/************************************/

.display-none {
  display: none;
}

.inline-block {
  display: inline-block;
}

.block {
  display: block;
}

.none {
  display: none;
}

/*********** Border style*************/

/************Button Style*****************/

.btn-bg {
  color: #ffffff !important;
  background: -webkit-linear-gradient(#000000, #000000);
  border: -webkit-linear-gradient(#000000, #000000);
  border-radius: 32px;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 10px;
  padding-bottom: 10px;
  -webkit-box-shadow: unset !important;
  box-shadow: unset !important;
}

.blue-bg {
  color: #ffffff !important;
  background: -webkit-linear-gradient(#000000, #000000);
  border: -webkit-linear-gradient(#000000, #000000);
  border-radius: 32px;
  padding-left: 25px;
  padding-right: 25px;
  padding-top: 15px;
  padding-bottom: 15px;
  margin: 5px;
  -webkit-box-shadow: unset !important;
  box-shadow: unset !important;
}

.btn-bg:hover {
  opacity: 0.8;
}

.btn-border {
  color: #000000;
  border: 1px solid #000000;
  border-radius: 32px;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 5px;
  padding-bottom: 5px;
  margin: 5px;
  -webkit-box-shadow: unset !important;
  box-shadow: unset !important;
  -webkit-transition: 0.3s ease all;
  -o-transition: 0.3s ease all;
  transition: 0.3s ease all;
}

/*************Font size stylesheet*****************/

/***************Common style*************************/

ul {
  list-style-type: none;
  padding: 0px;
  margin: 0px;
}

a {
  text-decoration: none !important;
}

a:hover,
a:active,
a:focus {
  outline: unset !important;
  color: #000000;
}

.clear {
  clear: both;
}

button:focus {
  outline: unset !important;
}

/*********************************************************************************************/

.width100 {
  width: 100%;
}

.width75 {
  width: 75%;
}

.width50 {
  width: 50%;
}

.width25 {
  width: 25%;
}

/******************************************Common Style Sheet**************************************/

.regular-font {
  font-family: QanelasSoft-Regular;
}

.semibold-font {
  font-family: averta-semibold;
}

.bold-font {
  font-family: QanelasSoft-Bold;
}

/*xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx*/
.amgAuto {
  margin: auto;
}  

.amg0 {
  margin: 0px;
}

.amg5 {
  margin: 5px;
}

.amg10 {
  margin: 10px;
}

.amg15 {
  margin: 15px;
}

.amg20 {
  margin: 20px;
}

.amg25 {
  margin: 25px;
}

.amg30 {
  margin: 30px;
}

.amg35 {
  margin: 35px;
}

.amg40 {
  margin: 40px;
}

.amg45 {
  margin: 45px;
}

.amg50 {
  margin: 50px;
}

.amg55 {
  margin: 55px;
}

.amg60 {
  margin: 60px;
}

.amt0 {
  margin-top: 0px;
}

.amt5 {
  margin-top: 5px;
}

.amt10 {
  margin-top: 10px;
}

.amt15 {
  margin-top: 15px;
}

.amt20 {
  margin-top: 20px;
}

.amt25 {
  margin-top: 25px;
}

.amt30 {
  margin-top: 30px;
}

.amt35 {
  margin-top: 35px;
}

.amt40 {
  margin-top: 40px;
}

.amt45 {
  margin-top: 45px;
}

.amt50 {
  margin-top: 50px;
}

.amt55 {
  margin-top: 55px;
}

.amt60 {
  margin-top: 60px;
}

.amb0 {
  margin-bottom: 0px;
}

.amb5 {
  margin-bottom: 5px;
}

.amb10 {
  margin-bottom: 10px;
}

.amb15 {
  margin-bottom: 15px;
}

.amb20 {
  margin-bottom: 20px;
}

.amb25 {
  margin-bottom: 25px;
}

.amb30 {
  margin-bottom: 30px;
}

.amb35 {
  margin-bottom: 35px;
}

.amb40 {
  margin-bottom: 40px;
}

.amb45 {
  margin-bottom: 45px;
}

.amb50 {
  margin-bottom: 50px;
}

.amb55 {
  margin-bottom: 55px;
}

.amb60 {
  margin-bottom: 60px;
}

.amb65 {
  margin-bottom: 65px;
}

.amb70 {
  margin-bottom: 70px;
}

.amb100 {
  margin-bottom: 100px;
}

.aml0 {
  margin-left: 0px;
}

.aml5 {
  margin-left: 5px;
}

.aml10 {
  margin-left: 10px;
}

.aml15 {
  margin-left: 15px;
}

.aml20 {
  margin-left: 20px;
}

.aml25 {
  margin-left: 25px;
}

.aml30 {
  margin-left: 30px;
}

.aml35 {
  margin-left: 35px;
}

.aml40 {
  margin-left: 40px;
}

.aml45 {
  margin-left: 45px;
}

.aml50 {
  margin-left: 50px;
}

.aml55 {
  margin-left: 55px;
}

.aml60 {
  margin-left: 60px;
}

.amr0 {
  margin-right: 0px;
}

.amr5 {
  margin-right: 5px;
}

.amr10 {
  margin-right: 10px;
}

.amr15 {
  margin-right: 15px;
}

.amr20 {
  margin-right: 20px;
}

.amr25 {
  margin-right: 25px;
}

.amr30 {
  margin-right: 30px;
}

.amr35 {
  margin-right: 35px;
}

.amr40 {
  margin-right: 40px;
}

.amr45 {
  margin-right: 45px;
}

.amr50 {
  margin-right: 50px;
}

.amr55 {
  margin-right: 55px;
}

.amr60 {
  margin-right: 60px;
}

.apd0 {
  padding: 0px;
}

.apd5 {
  padding: 5px;
}

.apd10 {
  padding: 10px;
}

.apd15 {
  padding: 15px;
}

.apd20 {
  padding: 20px;
}

.apd25 {
  padding: 25px;
}

.apd30 {
  padding: 30px;
}

.apd35 {
  padding: 35px;
}

.apd40 {
  padding: 40px;
}

.apd45 {
  padding: 45px;
}

.apd50 {
  padding: 50px;
}

.apd55 {
  padding: 55px;
}

.apd60 {
  padding: 60px;
}

.apt0 {
  padding-top: 0px;
}

.apt5 {
  padding-top: 5px;
}

.apt10 {
  padding-top: 10px;
}

.apt15 {
  padding-top: 15px;
}

.apt20 {
  padding-top: 20px;
}

.apt25 {
  padding-top: 25px;
}

.apt30 {
  padding-top: 30px;
}

.apt35 {
  padding-top: 35px;
}

.apt40 {
  padding-top: 40px;
}

.apt45 {
  padding-top: 45px;
}

.apt50 {
  padding-top: 50px;
}

.apt55 {
  padding-top: 55px;
}

.apt60 {
  padding-top: 60px;
}

.apb0 {
  padding-bottom: 0px;
}

.apb5 {
  padding-bottom: 5px;
}

.apb10 {
  padding-bottom: 10px;
}

.apb15 {
  padding-bottom: 15px;
}

.apb20 {
  padding-bottom: 20px;
}

.apb25 {
  padding-bottom: 25px;
}

.apb30 {
  padding-bottom: 30px;
}

.apb35 {
  padding-bottom: 35px;
}

.apb40 {
  padding-bottom: 40px;
}

.apb45 {
  padding-bottom: 45px;
}

.apb50 {
  padding-bottom: 50px;
}

.apb55 {
  padding-bottom: 55px;
}

.apb60 {
  padding-bottom: 60px;
}

.apl0 {
  padding-left: 0px;
}

.apl5 {
  padding-left: 5px;
}

.apl10 {
  padding-left: 10px;
}

.apl15 {
  padding-left: 15px;
}

.apl20 {
  padding-left: 20px;
}

.apl25 {
  padding-left: 25px;
}

.apl30 {
  padding-left: 30px;
}

.apl35 {
  padding-left: 35px;
}

.apl40 {
  padding-left: 40px;
}

.apl45 {
  padding-left: 45px;
}

.apl50 {
  padding-left: 50px;
}

.apl55 {
  padding-left: 55px;
}

.apl60 {
  padding-left: 60px;
}

.apr0 {
  padding-right: 0px;
}

.apr5 {
  padding-right: 5px;
}

.apr10 {
  padding-right: 10px;
}

.apr15 {
  padding-right: 15px;
}

.apr20 {
  padding-right: 20px;
}

.apr25 {
  padding-right: 25px;
}

.apr30 {
  padding-right: 30px;
}

.apr35 {
  padding-right: 35px;
}

.apr40 {
  padding-right: 40px;
}

.apr45 {
  padding-right: 45px;
}

.apr50 {
  padding-right: 50px;
}

.apr55 {
  padding-right: 55px;
}

.apr60 {
  padding-right: 60px;
}

/*xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx*/

.border {
  border: 1px solid #e1e8eb !important;
}

.border-top {
  border-top: 1px solid #e1e8eb !important;
}

.border-bottom {
  border-bottom: 1px solid #e1e8eb !important;
}

.border-left {
  border-left: 1px solid #e1e8eb !important;
}

.border-right {
  border-right: 1px solid #e1e8eb !important;
}

.dark-border {
  border: 1px solid #d7dbe1 !important;
}

.dark-border-top {
  border-top: 1px solid #d7dbe1 !important;
}

.dark-border-bottom {
  border-bottom: 1px solid #d7dbe1 !important;
}

.dark-border-right {
  border-right: 1px solid #d7dbe1 !important;
}

.dark-border-left {
  border-left: 1px solid #d7dbe1 !important;
}

.border-none {
  border: none !important;
}

.border-top-none {
  border-top: none !important;
}

.border-bottom-none {
  border-bottom: none !important;
}

.border-left-none {
  border-left: none !important;
}

.border-right-none {
  border-right: none !important;
}

.dark-border-none {
  border: none !important;
}

.dark-border-top-none {
  border-top: none !important;
}

.dark-border-bottom-none {
  border-bottom: none !important;
}

.dark-border-right-none {
  border-right: none !important;
}

.dark-border-left-none {
  border-left: none !important;
}

.border-radius {
  border-radius: 32px !important;
}

.border-bottom-radius {
  border-bottom-left-radius: 5px !important;
  border-bottom-right-radius: 5px !important;
}

.input-border {
  border: 1px solid #a9b1bc !important;
}

.box-shadow-none {
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}

.box-shadow {
  -webkit-box-shadow: 0 4px 6px -6px #0003;
  box-shadow: 0 4px 6px -6px #0003;
}

.blue-border {
  border: 2px solid #1c74f9b3;
}

.blue-border-color,
.blue-border-color:hover,
.blue-border-color:focus {
  border-color: #1c74f9b3 !important;
}

.border-width {
  border-width: 1px !important;
}

.border-width2 {
  border-width: 2px !important;
}

.border-width3 {
  border-width: 3px !important;
}

.border-bottom-only {
  border: none !important;
  border-bottom: 1px solid #e1e8eb !important;
}

.circle-img {
  border-radius: 50%;
}

/*xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx*/

.apk-60 {
  font-size: 60px;
}

.apk-48 {
  font-size: 48px;
}

.apk-36 {
  font-size: 36px;
}

.apk-30 {
  font-size: 30px;
}

.apk-24 {
  font-size: 24px;
}

.apk-22 {
  font-size: 22px;
}

.apk-20 {
  font-size: 20px;
}

.apk-16 {
  font-size: 16px;
}

.mdh-18 {
  font-size: 18px;
}

.sma-16 {
  font-size: 16px;
}

.esmh-14 {
  font-size: 14px;
}

.esmh-12 {   
  font-size: 12px;
}

/*xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx*/

.primary-color {
  color: #000000;
}

.secondary_color {
  color: #444444;
}

.secondaryText {
  color: #888888;
}

.secondary-color {
  color: #444444;
}

.gray-color {
  color: #888888;
}

.blue-color {
  color: #1c74f9b3;
}

.green-color {
  color: #16cd6c;
}

.white-color {
  color: #ffffff;
}

.pink-color {
  color: #000000;
}

.orange-color {
  color: #ff7d36;
}

.lite-blue-color {
  color: #f2f9fd;
}

.banner-blue-color {
  color: #189ae6;
}

.red-color {
  color: #f6380d;
}

/****************************************************/

.primary-bg {
  background: -webkit-linear-gradient(#000000, #000000);
}

.secondary-bg {
  background-color: #444444;
}

.gray-bg {
  background-color: #888888;
}

.blue-bg {
  background-color: #1c74f9b3;
}

.green-bg {
  background-color: #16cd6c;
}

.black-bg {
  background-color: #000000;
}

.white-bg {
  background-color: #ffffff !important;
}

.pink-bg {
  background-color: #000000;
}

.orange-bg {
  background-color: #ff7d36;
}

.lite-blue-bg {
  background-color: #f2f9fd;
}

.banner-blue-bg {
  background: #189ae6;
}

.red-bg {
  background: #f6380d;
}

.icon-bg {
  background: #d7e1e4;
}

/*******************************************Responsive code******************************************************/

@media (min-width: 320px) and (max-width: 991px) {
  .arm0 {
      margin: 0px;
  }

  .arm5 {
      margin: 5px;
  }

  .arm10 {
      margin: 10px;
  }

  .arm15 {
      margin: 15px;
  }

  .arm20 {
      margin: 20px;
  }

  .arm25 {
      margin: 25px;
  }

  .arm30 {
      margin: 30px;
  }

  .arm35 {
      margin: 35px;
  }

  .arm40 {
      margin: 40px;
  }

  .arm45 {
      margin: 45px;
  }

  .arm50 {
      margin: 50px;
  }

  .arm55 {
      margin: 55px;
  }

  .arm60 {
      margin: 60px;
  }

  .armt0 {
      margin-top: 0px !important;
  }

  .armt5 {
      margin-top: 5px !important;
  }

  .armt10 {
      margin-top: 10px !important;
  }

  .armt15 {
      margin-top: 15px !important;
  }

  .armt20 {
      margin-top: 20px !important;
  }

  .armt30 {
      margin-top: 30px !important;
  }

  .armt50 {
      margin-top: 50px !important;
  }

  .armt80 {
      margin-top: 80px !important;
  }

  .armb0 {
      margin-bottom: 0px !important;
  }

  .armb5 {
      margin-bottom: 5px !important;
  }

  .armb10 {
      margin-bottom: 10px !important;
  }

  .armb15 {
      margin-bottom: 15px !important;
  }

  .armb20 {
      margin-bottom: 20px !important;
  }

  .arml0 {
      margin-left: 0px !important;
  }

  .arml5 {
      margin-left: 5px !important;
  }

  .arml10 {
      margin-left: 10px !important;
  }

  .arml15 {
      margin-left: 15px !important;
  }

  .arml20 {
      margin-left: 20px !important;
  }

  .armr0 {
      margin-right: 0px !important;
  }

  .armr5 {
      margin-right: 5px !important;
  }

  .armr10 {
      margin-right: 10px !important;
  }

  .armr15 {
      margin-right: 15px !important;
  }

  .armr20 {
      margin-right: 20px !important;
  }

  .arp0 {
      padding: 0px;
  }

  .arp5 {
      padding: 5px;
  }

  .arp10 {
      padding: 10px;
  }

  .arp15 {
      padding: 15px;
  }

  .arp20 {
      padding: 20px;
  }

  .arp25 {
      padding: 25px;
  }

  .arp30 {
      padding: 30px;
  }

  .arp35 {
      padding: 35px;
  }

  .arp40 {
      padding: 40px;
  }

  .arp45 {
      padding: 45px;
  }

  .arp50 {
      padding: 50px;
  }

  .arp55 {
      padding: 55px;
  }

  .arp60 {
      padding: 60px;
  }

  .arpt0 {
      padding-top: 0px !important;
  }

  .arpt5 {
      padding-top: 5px !important;
  }

  .arpt10 {
      padding-top: 10px !important;
  }

  .arpt15 {
      padding-top: 15px !important;
  }

  .arpt20 {
      padding-top: 20px !important;
  }

  .arpb0 {
      padding-bottom: 0px !important;
  }

  .arpb5 {
      padding-bottom: 5px !important;
  }

  .arpb10 {
      padding-bottom: 10px !important;
  }

  .arpb15 {
      padding-bottom: 15px !important;
  }

  .arpb20 {
      padding-bottom: 20px !important;
  }

  .arpl0 {
      padding-left: 0px !important;
  }

  .arpl5 {
      padding-left: 5px !important;
  }

  .arpl10 {
      padding-left: 10px !important;
  }

  .arpl15 {
      padding-left: 15px !important;
  }

  .arpl20 {
      padding-left: 20px !important;
  }

  .arpr0 {
      padding-right: 0px !important;
  }

  .arpr5 {
      padding-right: 5px !important;
  }

  .arpr10 {
      padding-right: 10px !important;
  }

  .arpr15 {
      padding-right: 15px !important;
  }

  .arpr20 {
      padding-right: 20px !important;
  }

  /**************************display options*************************************/
  .resp-diplay {
      display: block;
  }

  .resp-none {
      display: none;
  }

  .resp-inline-block {
      display: inline-block;
  }

  .resp-flex {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
  }

  /*************************************************************************/
  .resp-apk-60 {
      font-size: 60px;
  }

  .resp-apk-48 {
      font-size: 48px;
  }

  .resp-apk-36 {
      font-size: 36px;
  }

  .resp-apk-30 {
      font-size: 30px;
  }

  .resp-apk-24 {
      font-size: 24px;
  }

  .resp-apk-20 {
      font-size: 20px;
  }

  .resp-apk-18 {
      font-size: 18px;
  }

  .resp-apk-16 {
      font-size: 16px;
  }

  .resp-apk-14 {
      font-size: 14px;
  }

  /****************************************************************************/
  .resp-width100 {
      width: 100%;
  }

  .resp-width50 {
      width: 50%;
  }

  .resp-width25 {
      width: 25%;
  }

  .reswhite-bg {
      background-color: #ffffff;
  }

  .reswhite-color {
      color: #ffffff;
  }

  .resblue-color {
      color: #1c74f9b3 !important;
  }

  /**********************************************************/
  .resp-border-none {
      border: none !important;
  }

  .resp-border-top-none {
      border-top: none !important;
  }

  .resp-border-bottom-none {
      border-bottom: none !important;
  }

  .resp-border-left-none {
      border-left: none !important;
  }

  .resp-border-right-none {
      border-right: none !important;
  }

  .resp-dark-border-none {
      border: none !important;
  }

  .resp-dark-border-top-none {
      border-top: none !important;
  }

  .resp-dark-border-bottom-none {
      border-bottom: none !important;
  }

  .resp-dark-border-right-none {
      border-right: none !important;
  }

  .resp-dark-border-left-none {
      border-left: none !important;
  }
}

/***************************Common style codes**************************************/

body {
  font-family: QanelasSoft-Regular;
  font-size: 18px;
  outline: unset !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #444444;
  margin: 0;
  padding: 0;
  height: 100%;
  background-color: #f8f8f8;
}

/*loader*/

.loder-icon {
  position: relative;
}

.loader.available {
  opacity: 0;
}

.loader {
  left: 0px;
  top: 0px;
  z-index: 3;
  width: 100%;
  background-color: #fff;
  height: 100vh;
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  position: absolute;
  width: 100%;
}

.loading__dot {
  position: relative;
  color: #000000;
  left: 10%
}

@media (max-width:991px) {
  .loading__dot {
      position: relative;
      color: #000000;
      left: 0%;
  }
}

.loading__dot.available {
  opacity: 0;
}

@-webkit-keyframes blink {
  50% {
      color: transparent;
  }
}

@keyframes blink {
  50% {
      color: transparent;
  }
}

.loading__dot {
  -webkit-animation: 1s blink infinite;
  animation: 1s blink infinite;
  font-size: 75px;
  text-align: center;
}

.loading__dot:nth-child(2) {
  -webkit-animation-delay: 250ms;
  animation-delay: 250ms;
}

.loading__dot:nth-child(3) {
  -webkit-animation-delay: 500ms;
  animation-delay: 500ms;
}

/*loader*/

/*header*/

.profile-header-top {
  width: 100%;
  padding: 25px;
  border-bottom: 1px solid #ececec;
}

.heading-left-top {
  font-size: 20px;
}

.heading-left-top a {
  color: #000000 !important;
}

.content-wrapper {
  position: relative;
  min-height: 100vh;
  background-color: #fff;
}

.content-details {
  padding: 0px 15px;
}

img.logo-size {
  width: 120px !important;
}

img.img-ressize {
  width: 120px !important;
}

.main-header {
  width: 100%;
  float: left;
}

.heading-right-top img {
  width: 26px;
  cursor: pointer;
}

.res-heading-top img {
  width: 26px;
  cursor: pointer;
}

.res-heading-top {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  width: 100%;
}

.profile-header {
  width: 100%;
}

.profile-header-top img {
  width: 26px;
}

.right-bg {
  width: 100%;
  display: inline-block;
}

@media only screen and (max-width: 1200px) and (min-width: 992px) {
  .homepg {
      width: 72% !important; 
  }

  .main-sidebar {
      width: 28% !important;
  }

  .profile-logo {
      width: 27%;
  }

  .profile-header {
      width: 73% !important;
  }
}

@media screen and (max-width: 991px) {
  .content-wrapper {
      width: 100% !important;
  }
}

@media screen and (min-width: 992px) {
  .res-heading-top {
      display: none;
  }
}

/*header*/

/*sidebar*/

.left-sidemenu .res-menu {
  position: relative;
  width: 100%;
}

/*.left-sidemenu .sidebar ul.sidebar-menu li {
padding: 15px;
line-height: 37px; }
*/

.left-sidemenu .sidebar-menu {
  width: 100%;
  float: left;
}

.left-sidemenu .main-sidebar {
  width: 24% !important;
}

.left-sidemenu img.normal-logo {
  width: 120px !important;
}

.left-sidemenu .sidebar-menu1 { 
  width: 100%;
}

.left-sidemenu .sidebar-menu li>a.active {
  color: #000000;
  font-weight: bold;
}

.text-bold {
  font-weight: bold !important;
}

.left-sidemenu .main-sidebar {
  -webkit-transition: none !important;
  -o-transition: none !important;
  transition: none !important;
}

.left-sidemenu .mobile-menu h1 {
  background-color: #fff !important;
  line-height: 27px !important;
}

.left-sidemenu .mobile-menu ul li {
  padding: 0px !important;
}

.left-sidemenu .mobile-menu ul li ul li {
  padding: 15px !important;
}

.left-sidemenu .menu-overflow1 {
  white-space: nowrap;
  width: 165px;
  overflow: hidden;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
  vertical-align: middle;
}

.left-sidemenu .mobile-menu nav {
  overflow: scroll;
}

.left-sidemenu .info-location {
  width: 70%;
}

.left-sidemenu .info-location .info-details {
  overflow: hidden;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 125px;
}

.left-sidemenu .pull-left.image {
  float: left;
}

.left-sidemenu .whole-details {
  width: 88%;
}

.left-sidemenu .settingpage img {
  width: 26px;
}

.left-sidemenu .navbar-static-top img {
  width: 32px;
}

.left-sidemenu .dropdown.messages-menu img {
  width: 32px;
}

.left-sidemenu .pull-left.info.location {
  float: left;
  width: 145px;
}

.left-sidemenu .logo-lg img {
  width: 40px;
}

.left-sidemenu .mobile-header-res img {
  width: 26px;
}

.left-sidemenu ul.sidebar-menu.tree li img {
  width: 32px;
}

.left-sidemenu .visitors {
  position: relative;
}

.left-sidemenu .visitorscount {
  position: absolute;
  right: 5px;
  background-image: url(../img/visitor_bg.png);
  width: 30px;
  height: 30px;
  background-size: contain;
  text-align: center;
  line-height: 28px;
  white-space: nowrap;
  overflow: hidden;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
}

.left-sidemenu .messagecount {
  position: absolute;
  right: 5px;
  background-image: url(../img/message_bg.png);
  width: 30px;
  height: 30px;
  background-size: contain;
  text-align: center;
  line-height: 28px;
  white-space: nowrap;
  overflow: hidden;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
}

.left-sidemenu .rf {
  position: absolute;
  right: 5px;
  background-image: url(../img/request_bg.png);
  width: 30px;
  height: 30px;
  background-size: contain;
  text-align: center;
  line-height: 28px;
  white-space: nowrap;
  overflow: hidden;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
}

.left-sidemenu .mobile-menu h1 {
  padding: 0px !important;
  padding-top: 10px !important;
}

.left-sidemenu .mobile-menu ul li:hover {
  background: transparent !important;
}

.left-sidemenu .sidebar-menu li>a {
  color: #444444;
}

.left-sidemenu .size-img {
  width: 50px;
  height: 50px;
}

.left-sidemenu .profile-icon-image {
  background: url(../img/1.jpg) no-repeat scroll center center/cover;
  height: 50px;
  width: 50px;
  max-width: 100%;
  overflow: hidden;
  -webkit-transition: all ease 0.5s;
  -o-transition: all ease 0.5s;
  transition: all ease 0.5s;
  border-radius: 50%;
}

@media only screen and (max-width: 1200px) and (min-width: 992px) {
  .left-sidemenu .homepg {
      width: 72%;
  }

  .left-sidemenu .main-sidebar {
      width: 28% !important;
  }
}

@media screen and (min-width: 361px) and (max-width: 576px) {
  .left-sidemenu .menu-overflow {
      white-space: nowrap;
      width: 235px !important;
      overflow: hidden;
      -o-text-overflow: ellipsis;
      text-overflow: ellipsis;
      vertical-align: middle;
  }
}

@media screen and (max-width: 360px) {
  .left-sidemenu .menu-overflow {
      white-space: nowrap;
      width: 165px !important;
      overflow: hidden;
      -o-text-overflow: ellipsis;
      text-overflow: ellipsis;
      vertical-align: middle;
  }
}

@media screen and (max-width: 767px) {
  .left-sidemenu .main-sidebar {
      overflow-y: scroll;
  }

  .left-sidemenu .menu-overflow {
      white-space: nowrap;
      width: 290px;
      overflow: hidden;
      -o-text-overflow: ellipsis;
      text-overflow: ellipsis;
      vertical-align: middle;
  }
}

@media screen and (min-width: 768px) {
  .left-sidemenu .menu-overflow {
      white-space: nowrap;
      width: 290px;
      overflow: hidden;
      -o-text-overflow: ellipsis;
      text-overflow: ellipsis;
      vertical-align: middle;
  }
}

/*sidebar*/

/*homepage*/

.home-page .homepg {
  width: 76%;
  margin-left: 0px;
}

.home-page .slidecaption h5 {
  margin-top: 40px;
}

.home-page .homepage-pg {
  max-width: 600px;
  margin: 0 auto;
  width: 100%;
}

.home-page .slick-slide img {
  max-width: 600px;
  border-radius: 4px;
  height: 600px;
  width: 100%;
}

.home-page .slick-next {
  background: url(../img/next-64.png) no-repeat center !important;
  width: 70px;
  height: 70px;
  border-radius: 50%;
  top: 64%;
  position: absolute;
  right: 23%;
}

.home-page .slick-next::before {
  content: "";
}

.home-page .slick-prev {
  background: url(../img/pri-64.png) no-repeat center !important;
  width: 70px;
  height: 70px;
  border-radius: 50%;
  position: absolute;
  top: 64%;
  z-index: 1;
  left: 22%;
}

.home-page .slick-prev::before {
  content: "" !important;
}

.home-page .slidecaption {
  position: absolute;
  top: 64%;
  text-align: center;
  width: 100%;
}

.home-page .like-icon {
  width: 70px;
  height: 70px;
  margin: 0 auto;
}

.home-page .like-icon img {
  width: 70px;
  height: 70px;
}

@media screen and (min-width: 320px) and (max-width: 500px) {
  .heading-left-top {
    font-size: 17px;  
  }

  .home-page .homepage-pg {
      max-width: 350px !important;
      margin: 0 auto;
  }

  .home-page .slick-slide img {
      max-width: 300px !important;
      margin: 0 auto;
  }
}

@media screen and (max-width: 768px) {
  .home-page .homepage-pg {
      max-width: 450px;
      margin: 0 auto;
  }

  .home-page .slick-slide img {
      max-width: 450px;
  }
}

@media screen and (max-width: 991px) {
  .home-page .homepg {
      width: 100%;
  }

  .home-page .slick-slide img {
      -o-object-fit: cover;
      object-fit: cover;
  }
}

@media screen and (max-height: 768px) {
  .home-page .slick-slide img {
      height: 500px;
      -o-object-fit: cover;
      object-fit: cover;
  }

  .home-page .like-icon img {
      height: auto;
  }
}

@media screen and (min-width: 991px) {
  .home-page .slick-slide img {
      -o-object-fit: cover;
      object-fit: cover;
  }
}

/*homepage*/

/*footer*/

.footer {
  padding: 15px 0px;
  float: left;
  width: 100%;
  bottom: 0;
  height: auto;
  background: #ffffff;
  word-wrap: break-word;
  position: absolute;
  bottom: 0px;
}

.footer a {
  color: #494949;
  word-wrap: break-word;
}

.footer .bold {
  font-weight: bold;
}

.app-download {
  text-align: right;
}

@media screen and (max-width: 1200px) {
  .app-download {
      text-align: center;
      margin-bottom: 10px;
  }
}

/*footer*/

/*Filter*/

.filter-page .content-wrapper {
  background-color: #ecf0f5;
}

.filter-page .right-bg {
  margin-bottom: 40px;
}

.filter-page .icon-bg.active {
  background: -webkit-linear-gradient(#000000, #000000);
}

.filter-page .agm {
  text-align: right;
}

.filter-page .input-range__label-container {
  display: none;
}

.filter-page .save-details {
  line-height: 27px;
}

.filter-page .res-heading-top {
  display: none;
}

.filter-page .iconimg {
  width: 100%;
}

.filter-page .setting-save {
  cursor: pointer;
}

.filter-page .iconimg h6 {
  white-space: nowrap;
  width: 100%;
  overflow: hidden;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
  padding-bottom: 5px;
}

.filter-page .iconimg img {
  background: #d7e1e4;
  border-radius: 50%;
  padding: 15px;
  cursor: pointer;
  width: 95px;
  height: 95px;
}

.filter-page img.navigation--active {
  background: -webkit-linear-gradient(#000000, #000000);
}

.filter-page .homepg {
  width: 76%;
  margin-left: 0px;
}

.filter-page .input-range__slider {
  margin-top: -10px;
}

@media screen and (min-height: 768px) {
  .filter-page .content-wrapper {
      min-height: 100vh;
  }
}

@media screen and (max-width: 360px) {
  .filter-page .iconimg img {
      width: 85px;
      height: 85px;
  }
}

/*Filter*/

/*settings*/

.setting-page .homepg {
  width: 76%;
  margin-left: 0px;
  background-color: #ecf0f5;
}

.setting-page .right-bg {
  margin-bottom: 40px;
}

.setting-page .setting-save {
  vertical-align: middle;
  line-height: 24px;
  cursor: pointer;
}

.setting-page .switch {
  position: relative;
  display: inline-block;
  width: 43px;
  height: 18px;
  float: right;
}

.setting-page .icon-bg.active {
  background: -webkit-linear-gradient(#000000, #000000);
}

.setting-page .switch input {
  display: none;
}

.setting-page .slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  -o-transition: .4s;
  transition: .4s;
}

.setting-page .slider:before {
  position: absolute;
  content: "";
  height: 23px;
  width: 23px;
  left: 0px;
  bottom: -2px;
  background-color: #eaf0f3;
  -webkit-transition: .4s;
  -o-transition: .4s;
  transition: .4s;
  -webkit-box-shadow: 2px 1px 1px 0px #888888;
  box-shadow: 2px 1px 1px 0px #888888;
}

.setting-page input.default:checked+.slider {
  background-color: #E2E2E2;
}

.setting-page input:focus+.slider {
  -webkit-box-shadow: 0 0 1px #2196F3;
  box-shadow: 0 0 1px #2196F3;
}

.setting-page input:checked+.slider:before {
  -webkit-transform: translateX(22px);
  -ms-transform: translateX(22px);
  transform: translateX(22px);
  background-color: #000000;
}

.setting-page .slider.round {
  border-radius: 34px;
}

.setting-page .slider.round:before {
  border-radius: 50%;
}

.setting-page iconimg {
  width: 100%;
}

.setting-page .iconimg img {
  background: gray;
  border-radius: 50%;
  padding: 15px;
  cursor: pointer;
  width: 75px;
  height: 75px;
}

.setting-page .iconimg h6 {
 text-transform: capitalize;   
  width: 75px;
}

.setting-page img.navigation--active {
  background: -webkit-linear-gradient(#000000, #000000);
}

@media screen and (min-width: 768px) {
  .setting-page .arrow-icon-none {
      display: none;
  }
}

/*settings*/

/*Profile*/

.profilePageHolder {
  display: flex;
  justify-content: center;
}

.profile-page {
  max-width: 600px;
}

.profile-page .homepg {
  width: 76%;
  margin-left: 0px;
}

.profile-page .interest-close {
  padding-top: 20px;
}

.profile-page .res-heading-top {
  display: none;
}

.profile-page #demo {
  height: 350px;
  overflow: auto;
}

.profile-page #pricinplan {
  padding-right: 0px !important;
}

.profile-page .form-control:focus {
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}

.profile-page .pointer-arrow button {
  cursor: pointer;
}

.profile-page .edit-img {
  position: relative;
  text-align: center;
  display: inline-block;
}

.profile-page #premium {
  padding: 0px 15px 0px 0px !important;
}

@media(min-width:576px) and (max-width:850px) {
  .profile-page #premium {
      padding: 0px 15px 0px 15px !important;
  }
}

.profile-page .premium-bluebtn {
  white-space: nowrap;
  max-width: 250px;
  overflow: hidden;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
  width: 100%;
}

.profile-page .navigation--active .premium-height {
  background: -webkit-linear-gradient(#000000, #000000);
  color: #fff;
}

.profile-page .navigation--active .day-count {
  background-color: #ff3775;
}

.vertically-center {
  height: 100%;
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin: 0 auto;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.profile-page #exampleModal {
  padding-right: 0px !important;
}

.profile-page img.close-size {
  width: 15px;
}

.profile-page .close {
  opacity: 1;
}

.profile-page .rigarr {
  width: 10px;
}

.profile-page .edit-photo {
  text-align: center;
}

.edit-pen {
  position: absolute;
  bottom: 10px;
  right: 0px;
  width: 40px;
  height: 40px;
}

.profile-page .edit-pen img {
  width: 20px;
  margin-top: 6px;
}

.profile-page .blue-prmium {
  text-align: center;
}

.profile-page .rigarr img {
  width: 20px;
  float: right;
}

.profile-page .btn-length {
  max-width: 300px;
  width: 100%;
  text-align: left;
  line-height: 19px;
}

.profile-page .info-option {
  float: left;
}

.profile-page .info-details {
  float: right;
}

.profile-page .info-details img {
  width: 16px;
  opacity: 0.5;
}

.profile-page .list-item {
  float: left;
  cursor: pointer;
}

.profile-page .modal-header.my-interst {
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.profile-page .btn-outline-secondary {
  background-color: none !important;
}

.profile-page .btn.search-btn {
  background-color: #ffff;
  padding: 0px;
}

.profile-page .btn.search-btn img {
  width: 32px;
}

.profile-page .interst-option {
  display: block;
  position: relative;
  cursor: pointer;
}

.profile-page .interst-option input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.profile-page #demo-premium1 img {
  height: 300px;
  -o-object-fit: cover;
  object-fit: cover;
}

.profile-page #demo-premium1 {
  height: 500px;
  overflow: auto;
}

.profile-page #demo-premium1 .carousel-indicators li {
  width: 10px !important;
  height: 10px !important;
  border-radius: 50% !important;
  border: 2px solid #e1e8eb !important;
}

.profile-page #demo-premium1 .carousel-indicators li.active {
  background: -webkit-linear-gradient(#000000, #000000) !important;
  border: 2px solid -webkit-linear-gradient(#000000, #000000) !important;
}

.profile-page #demo-premium1 .carousel-caption {
  position: static;
}

.profile-page #demo-premium1 .carousel-indicators {
  bottom: 36%;
}

.profile-page #premium .modal-dialog {
  width: 100%;
  max-width: 700px;
}

.profile-page #premium .slick-prev::before {
  content: "";
}

.profile-page #premium .slick-next::before {
  content: "";
}

.profile-page .premium-height {
  height: 190px;
}

.profile-page .price-plan .active {
  background: -webkit-linear-gradient(#000000, #000000);
  color: #fff;
}

.profile-page .price-plan {
  cursor: pointer;
}

.profile-page .navigation--active .save-offer {
  background-color: #40b9fe;
  border-radius: 32px 32px 0px 0px;
}

.profile-page .navigation--active .day-count {
  background-color: #ff3775;
  border-radius: 32px 32px 0px 0px;
}

@media screen and (max-width: 480px) {
  .profile-page .premium-height {
      height: 150px;
  }

  .profile-page .premium-height h1 {
      font-size: 20px;
  }

  .profile-page .premium-height h2 {
      font-size: 18px;
  }

  .profile-page .carousel-caption {
      padding: 0px !important;
  }

  .profile-page #demo-premium1 .carousel-indicators {
      bottom: 40%;
  }
}

.profile-page .interst-option input:checked~.checkmark {
  background-image: url(../img/tick.png);
  background-repeat: no-repeat;
  background-position: right center;
  background-size: 26px;
  color: #000000;
  position: absolute;
  right: 0px;
  width: 100%;
  height: 30px;
}

.profile-page .interst-option input:checked~.checkmark:after {
  display: block;
}

.profile-page .modal-footer.interest-save {
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.profile-page .modal-footer.interest-save .btn-bg {
  width: 100%;
  max-width: 400px;
}

.profile-page .modal-footer.interest-save .premium {
  width: 100%;
  text-align: center;
}

.profile-page .join-premium {
  text-align: center;
}

.profile-page .join-premium img {
  height: 250px;
  -o-object-fit: cover;
  object-fit: cover;
}

.profile-page .join-premium .carousel-caption {
  position: static !important;
}

.profile-page .carousel-indicators.top-indicator {
  bottom: 25%;
}

.profile-page .carousel-indicators.top-indicator li.active {
  background: -webkit-linear-gradient(#000000, #000000) !important;
  border: 2px solid -webkit-linear-gradient(#000000, #000000) !important;
}

.profile-page .carousel-indicators.top-indicator li {
  width: 10px !important;
  height: 10px !important;
  border-radius: 50% !important;
  border: 2px solid #e1e8eb !important;
}

@media screen and (max-width: 768px) {
  .profile-page .basic-overflow {
      white-space: nowrap;
      max-width: 100px;
      overflow: hidden;
      -o-text-overflow: ellipsis;
      text-overflow: ellipsis;
      vertical-align: middle;
  }

  .profile-page .demo-overflow {
      white-space: nowrap;
      max-width: 100px;
      overflow: hidden;
      -o-text-overflow: ellipsis;
      text-overflow: ellipsis;
      vertical-align: middle;
  }

  .profile-page .arrow-next {
      vertical-align: middle;
  }
}

@media screen and (max-width: 991px) {
  .profile-page .carousel-indicators.top-indicator {
      bottom: 31% !important;
  }
}

/*Profile*/

/*Peoplenearby*/

.people-near .user-input-wrp {
  position: relative;
}

.people-near .user-input-wrp .inputText {
  width: 100%;
  outline: none;
  border: none;
  border-bottom: 1px solid #e1e8eb;
  background-color: transparent;
}

.people-near .user-input-wrp .inputText:invalid {
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}

.people-near .user-input-wrp .inputText:focus {
  border-color: #000000;
  border-width: medium medium 2px;
}

.people-near .user-input-wrp .floating-label {
  position: absolute;
  pointer-events: none;
  top: 18px;
  left: 0px;
  -webkit-transition: 0.2s ease all;
  -o-transition: 0.2s ease all;
  transition: 0.2s ease all;
}


.people-near #location .form-control:focus {
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}

.people-near #location {
  padding-right: 0px !important;
}

.people-near .vertically-center {
  height: 100%;
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin: 0 auto;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.people-near .homepg {
  width: 76%;
  margin-left: 0px;
}

.people-near #location .close {
  margin: 0px !important;
  padding: 0px !important;
}

.people-near #location .modal-title {
  line-height: 27px !important;
}

.people-near .content-header2 {
  height: 100%;
}

.people-near .no-people {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.people-near .close {
  opacity: 1;
}

.close img {
  width: 15px;
}

.people-near .circle-ripple img {
  width: 220px;
  height: 220px;
  border: 5px solid #fff;
}

.people-near .circle-ripple {
  width: 220px;
  height: 220px;
  border-radius: 50%;
  -webkit-animation: ripple 0.7s linear infinite;
  animation: ripple 0.7s linear infinite;
}

@-webkit-keyframes ripple {
  0% {
      -webkit-box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.3), 0 0 0 1em rgba(0, 0, 0, 0.3), 0 0 0 3em rgba(0, 0, 0, 0.3), 0 0 0 5em rgba(0, 0, 0, 0.3);
      box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.3), 0 0 0 1em rgba(0, 0, 0, 0.3), 0 0 0 3em rgba(0, 0, 0, 0.3), 0 0 0 5em rgba(0, 0, 0, 0.3);
  }

  100% {
      -webkit-box-shadow: 0 0 0 1em rgba(0, 0, 0, 0.3), 0 0 0 3em rgba(0, 0, 0, 0.3), 0 0 0 5em rgba(0, 0, 0, 0.3), 0 0 0 8em rgba(0, 0, 0, 0);
      box-shadow: 0 0 0 1em rgba(0, 0, 0, 0.3), 0 0 0 3em rgba(0, 0, 0, 0.3), 0 0 0 5em rgba(0, 0, 0, 0.3), 0 0 0 8em rgba(0, 0, 0, 0);
  }
}

@keyframes ripple {
  0% {
      -webkit-box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.3), 0 0 0 1em rgba(0, 0, 0, 0.3), 0 0 0 3em rgba(0, 0, 0, 0.3), 0 0 0 5em rgba(0, 0, 0, 0.3);
      box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.3), 0 0 0 1em rgba(0, 0, 0, 0.3), 0 0 0 3em rgba(0, 0, 0, 0.3), 0 0 0 5em rgba(0, 0, 0, 0.3);
  }

  100% {
      -webkit-box-shadow: 0 0 0 1em rgba(0, 0, 0, 0.3), 0 0 0 3em rgba(0, 0, 0, 0.3), 0 0 0 5em rgba(0, 0, 0, 0.3), 0 0 0 8em rgba(0, 0, 0, 0);
      box-shadow: 0 0 0 1em rgba(0, 0, 0, 0.3), 0 0 0 3em rgba(0, 0, 0, 0.3), 0 0 0 5em rgba(0, 0, 0, 0.3), 0 0 0 8em rgba(0, 0, 0, 0);
  }
}

/*New people*/

.new-people .homepg {
  width: 76%;
  margin-left: 0px;
}

.new-people .no-onlinemember {
  position: relative;
  width: 150px;
  height: 150px;
}

.new-people .res-heading-top {
  position: relative;
}

.new-people .right-bg {
  display: block;
}

.new-people .no-onlinemember:hover .hovereffect-head {
  opacity: 1;
  -webkit-transition: all 0.4s ease-in-out;
  -o-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
  padding-top: 45px;
}

.new-people .circle-effects {
  -webkit-transition: all 0.4s ease-in-out;
  -o-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
}

.new-people .hovereffect-head {
  opacity: 0;
  position: absolute;
  top: 0px;
  display: block;
  width: 100%;
  height: 100%;
  background-color: #eeee;
  -webkit-transition: all 0.4s ease-in-out;
  -o-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
  cursor: pointer;
}

.new-people .circle-effects:hover .hovereffect-head {
  opacity: 1;
  -webkit-transition: all 0.4s ease-in-out;
  -o-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
  padding-top: 45px;
}

.new-people .whole-pg1:hover .figcaption h5 {
  color: #000000;
}

.new-people .whole-pg2:hover .figcaption h5 {
  color: #000000;
}

@media screen and (max-width: 768px) {
  .new-people .whole-pg1 {
      position: relative;
  }

  .new-people .whole-pg2 {
      position: relative;
  }

  .new-people .online-icon {
      position: absolute !important;
      bottom: 60px;
  }

  .new-people .online-icon img {
      position: static;
      right: 14px !important;
      bottom: 0px !important;
  }
}

@media only screen and (min-width: 320px) and (max-width: 400px) {
  .new-people .online-member {
      height: 55px !important;
      width: 55px !important;
  }

  .new-people .no-onlinemember {
      height: 55px !important;
      width: 55px !important;
  }

  .new-people .new-findppl {
      padding-top: 110px !important;
  }

  .new-people .whole-pg2 {
      margin-top: -75px !important;
  }

  .new-people .circle-effects:hover .hovereffect-head {
      padding: 4px !important;
  }

  .new-people .no-onlinemember:hover .hovereffect-head {
      padding: 4px !important;
  }

  .new-people .online-icon img {
      right: 0px !important;
  }

  .new-people .online-icon {
      bottom: 46px !important;
  }

  .new-people .hovereffect-head h4 {
      font-size: 16px;
  }

  .new-people .hovereffect-head h6 {
      font-size: 14px;
  }

  .new-people .backgroundbg {
      height: 275px;
  }
}

.new-people h5.text-overflow {
  white-space: nowrap;
  width: 100%;
  overflow: hidden;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
}

.new-people .become-primiuem {
  width: 100%;
  max-width: 230px;
  margin: 0px;
}

@media only screen and (min-width: 401px) and (max-width: 480px) {
  .new-people .arch-img img {
      width: 150px;
      height: auto;
  }

  .new-people .backgroundbg {
      height: 275px;
  }

  .new-people .online-member {
      height: 75px !important;
      width: 75px !important;
  }

  .new-people .no-onlinemember {
      height: 75px !important;
      width: 75px !important;
  }

  .new-people .new-findppl {
      padding-top: 100px !important;
  }

  .new-people .whole-pg2 {
      margin-top: -80px !important;
  }

  .new-people .unlimited-ppl {
      background-size: 100% !important;
  }

  .new-people .circle-effects:hover .hovereffect-head {
      padding: 5px !important;
  }

  .new-people .online-icon img {
      width: 10px !important;
      height: auto !important;
  }

  .new-people .no-onlinemember:hover .hovereffect-head {
      padding: 5px !important;
  }

  .new-people .online-icon {
      bottom: 47px;
  }
}

@media screen and (min-width: 481px) and (max-width: 768px) {
  .new-people .online-member {
      height: 100px !important;
      width: 100px !important;
  }

  .new-people .no-onlinemember {
      height: 100px !important;
      width: 100px !important;
  }

  .new-people .whole-pg2 {
      margin-top: -115px !important;
  }

  .new-people .new-findppl {
      padding-top: 135px !important;
  }

  .new-people .no-onlinemember:hover .hovereffect-head {
      padding-top: 30px !important;
  }

  .new-people .circle-effects:hover .hovereffect-head {
      padding-top: 30px !important;
  }
}

.new-people img.logo-size {
  width: 120px !important;
}

.new-people .whole-pg2 {
  margin-top: -150px;
}

.new-people .new-findppl img {
  width: 150px;
  height: 150px;
}

.new-people .new-findppl {
  padding-top: 200px;
}

.new-people .online-member {
  position: relative;
}

.new-people .online-icon {
  position: relative;
}

.new-people .online-icon img {
  position: absolute;
  width: 15px;
  height: 15px;
  right: 30px;
  bottom: 8px;
}

.new-people .online-member {
  background: url(../img/1.jpg) no-repeat scroll center center/cover;
  height: 150px;
  width: 150px;
  overflow: hidden;
  border-radius: 50%;
}

.new-people .online-member.img2-bg {
  background: url(../img/1.jpg) no-repeat scroll center center/cover;
}

.new-people .online-member.img3-bg {
  background: url(../img/2.jpg) no-repeat scroll center center/cover;
}

.new-people .online-member.img4-bg {
  background: url(../img/3.jpg) no-repeat scroll center center/cover;
}

.new-people .online-member.img5-bg {
  background: url(../img/1.jpg) no-repeat scroll center center/cover;
}

.new-people .no-onlinemember {
  background: url(../img/2.jpg) no-repeat scroll center center/cover;
  height: 150px;
  width: 150px;
  overflow: hidden;
  position: relative;
  border-radius: 50%;
}

.new-people .unlimited-ppl {
  background-position: bottom center;
  background-repeat: no-repeat;
  background-size: 77%;
}

.new-people .anoo {
  position: relative;
}

.new-people .unlim-head h3 {
  text-align: center;
}

.new-people h5.text-overflow {
  white-space: nowrap;
  width: 100%;
  overflow: hidden;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
}

.new-people h6.text-overflow {
  white-space: nowrap;
  width: 100%;
  overflow: hidden;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
}

@media screen and (max-width: 991px) {
  .new-people .new-findppl img {
      width: 150px;
      height: auto;
  }

  .new-people .online-icon img {
      width: 15px;
  }
}

@media only screen and (min-width: 769px) and (max-width: 991px) {
  .new-people .new-findppl {
      position: absolute;
      top: 175px;
      padding-top: 0px !important;
  }
}

/*new people*/

/*add people*/

.add-people .homepg {
  width: 76%;
  margin-left: 0px;
}

.add-people .interest ul li {
  display: inline-block;
}

.add-people .visitor-dropdown .dropdown-item.active,
.add-people .dropdown-item:active {
  color: #444444 !important;
  background-color: transparent !important;
}

.add-people .visitor-dropdown .block-list {
  visibility: hidden;
  opacity: 0;
  width: 150px;
  background-color: #fff;
  position: absolute;
  -webkit-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  transition: all 0.5s ease;
  right: 5px;
  padding: 5px;
  display: none;
  -webkit-box-shadow: 2px 2px 2px 0px rgba(0, 0, 0, 0.07);
  box-shadow: 2px 2px 2px 0px rgba(0, 0, 0, 0.07);
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  line-height: 27px;
  font-size: 16px;
  text-align: left;
  z-index: 1;
}

.add-people .vertically-center {
  height: 100%;
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin: 0 auto;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.add-people .visitor-dropdown img {
  width: 8px;
  cursor: pointer;
}

.add-people .banner-close {
  position: absolute;
  right: 0px;
  top: 0px;
  width: 35px !important;
  height: 35px !important;
  cursor: pointer;
  z-index: 1;
}

.add-people .view-profile {
  background: url(../img/banner1.jpg) no-repeat scroll center center/cover;
  height: 500px;
  max-width: 100%;
  overflow: hidden;
  -webkit-transition: all ease 0.5s;
  -o-transition: all ease 0.5s;
  transition: all ease 0.5s;
  cursor: pointer;
}

.add-people .view-profile1 {
  background: url(../img/detail.jpg) no-repeat scroll center center/cover;
  height: 500px;
  max-width: 100%;
  overflow: hidden;
  -webkit-transition: all ease 0.5s;
  -o-transition: all ease 0.5s;
  transition: all ease 0.5s;
  cursor: pointer;
}

.add-people .view-profile2 {
  background: url(../img/pic1.jpg) no-repeat scroll center center/cover;
  height: 500px;
  max-width: 100%;
  overflow: hidden;
  -webkit-transition: all ease 0.5s;
  -o-transition: all ease 0.5s;
  transition: all ease 0.5s;
  cursor: pointer;
}

.add-people .view-profile3 {
  background: url(../img/addpr1.jpg) no-repeat scroll center center/cover;
  height: 500px;
  max-width: 100%;
  overflow: hidden;
  -webkit-transition: all ease 0.5s;
  -o-transition: all ease 0.5s;
  transition: all ease 0.5s;
  cursor: pointer;
}

.add-people .visitor-dropdown:hover .block-list {
  visibility: visible !important;
  opacity: 1 !important;
  display: block !important;
}

.add-people .carousel-indicators li {
  width: 10px !important;
  height: 10px !important;
  border-radius: 50% !important;
  border: 2px solid #e1e8eb !important;
  background-color: transparent;
}

.add-people .carousel-indicators li.active {
  background: -webkit-linear-gradient(#000000, #000000) !important;
  border: 2px solid -webkit-linear-gradient(#000000, #000000) !important;
}

.add-people #left-addpeople {
  background-image: url(../img/back.png);
  background-repeat: no-repeat;
  height: 32px;
  width: 32px;
  top: 50%;
  background-size: cover;
}

.add-people #right-addpeople {
  background-image: url(../img/back1.png);
  background-repeat: no-repeat;
  height: 32px;
  width: 32px;
  top: 50%;
  background-size: cover;
}

.add-people ul.block-list li:hover {
  color: #000000;
}

.add-people .fixed-position {
  position: relative;
  height: 70px;
  width: 100%;
  bottom: 70px;
}

.add-people .people-like {
  position: fixed;
  bottom: 190px;
  width: 100%;
  text-align: center;
  left: 50%;
}

.add-people .people-like ul {
  position: fixed;
}

.add-people .people-like ul li {
  display: inline-block;
}

.add-people .visitor-content {
  width: 95%;
}

.add-people .visitor-dropdown {
  width: 5%;
  text-align: right;
}

.add-people #pop1 {
  padding-right: 0px !important;
}

.add-people #pop2 {
  padding-right: 0px !important;
}

.add-people #pop3 {
  padding-right: 0px !important;
}

.add-people #pop1 .modal-dialog {
  width: 100%;
  max-width: 100%;
}

.add-people #pop1 .modal-content {
  background-color: transparent;
}

.add-people #pop2 .modal-dialog {
  width: 100%;
  max-width: 100%;
}

.add-people #pop2 .modal-content {
  background-color: transparent;
}

.add-people #pop3 .modal-dialog {
  width: 100%;
  max-width: 100%;
}

.add-people #pop3 .modal-content {
  background-color: transparent;
}

@media only screen and (max-width: 991px) and (min-width: 320px) {
  .add-people .people-like {
      position: fixed;
      bottom: 190px;
      width: 200PX;
      text-align: center;
      left: 0;
      right: 0;
      margin: 0 auto;
  }
}

/*add people*/

/*visitor*/

.visitors-page .whole-pg {
  -webkit-filter: blur(2px);
  filter: blur(2px);
  margin: 4px;
}

.visitors-page .no-onlinemember {
  position: relative;
  width: 150px;
  height: 150px;
}

.visitors-page .online-icon {
  position: relative;
}

.visitors-page .online-icon img {
  position: absolute;
  width: 15px;
  height: 15px;
  right: 30px;
  bottom: 8px;
}

.visitors-page .no-onlinemember:hover .hovereffect-head {
  opacity: 1;
  -webkit-transition: all 0.4s ease-in-out;
  -o-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
  padding-top: 45px;
}

.visitors-page .online-member {
  position: relative;
}

.visitors-page .circle-effects {
  -webkit-transition: all 0.4s ease-in-out;
  -o-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
}

.visitors-page .hovereffect-head {
  opacity: 0;
  position: absolute;
  top: 0px;
  display: block;
  width: 100%;
  height: 100%;
  display: none;
  background-color: #eeee;
  -webkit-transition: all 0.4s ease-in-out;
  -o-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
  cursor: pointer;
}

.visitors-page .whole-pg1 {
  margin-top: -30px;
  -webkit-filter: blur(2px);
  -moz-filter: blur(2px);
  -o-filter: blur(2px);
  -ms-filter: blur(2px);
  filter: blur(2px);
}

.visitors-page .whole-pg2 {
  margin-top: -100px;
  -webkit-filter: blur(2px);
  -moz-filter: blur(2px);
  -o-filter: blur(2px);
  -ms-filter: blur(2px);
  filter: blur(2px);
}

.visitors-page .circle-effects:hover .hovereffect-head {
  opacity: 1;
  -webkit-transition: all 0.4s ease-in-out;
  -o-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
  padding-top: 45px;
}

.visitors-page .whole-pg1:hover .figcaption h5 {
  color: #000000;
}

.visitors-page .whole-pg2:hover .figcaption h5 {
  color: #000000;
}

.visitors-page .online-icon img {
  position: absolute;
  width: 15px !important;
  height: 15px !important;
  right: 30px;
  bottom: 8px;
}

.visitors-page .online-member {
  position: relative;
  background: url(../img/pic3.png) no-repeat scroll center center/cover;
  height: 150px;
  width: 150px;
  overflow: hidden;
  border-radius: 50%;
}

.visitors-page img.logo-size {
  width: 120px !important;
}

.visitors-page .online-member.img2-bg {
  background: url(../img/pic1.jpg) no-repeat scroll center center/cover;
}

.visitors-page .online-member.img3-bg {
  background: url(../img/pic2.jpg) no-repeat scroll center center/cover;
}

.visitors-page .online-member.img4-bg {
  background: url(../img/new1.png) no-repeat scroll center center/cover;
}

.visitors-page .online-member.img5-bg {
  background: url(../img/new3.jpg) no-repeat scroll center center/cover;
}

.visitors-page .arch-img {
  position: relative;
}

.visitors-page .backgroundbg {
  margin-bottom: 200px;
}

.visitors-page .whole-pg2 {
  margin-top: -150px;
}

.visitors-page .no-onlinemember {
  background: url(../img/new2.jpg) no-repeat scroll center center/cover;
  height: 150px;
  width: 150px;
  overflow: hidden;
  position: relative;
  border-radius: 50%;
}

.visitors-page .arch-img img {
  width: 150px;
  height: 150px;
}

.visitors-page .right-bg {
  margin-bottom: 30px;
}

@media only screen and (max-width: 1200px) and (min-width: 992px) {
  .visitors-page .homepg {
      width: 72%;
  }

  .visitors-page .main-sidebar {
      width: 28% !important;
  }
}

@media screen and (max-width: 991px) {
  .visitors-page .right-bg {
      margin-top: 50px;
  }

  .visitors-page .vistors-name.content-visit {
      display: none !important;
  }

  .visitors-page .new-findppl img {
      width: 150px;
      height: auto;
  }

  .visitors-page .online-icon img {
      width: 15px;
  }
}

@media screen and (max-width: 768px) {
  .visitors-page .whole-pg2 {
      position: relative;
  }

  .visitors-page .whole-pg1 {
      position: relative;
  }

  .visitors-page .online-icon {
      position: absolute !important;
      bottom: 60px;
  }

  .visitors-page .online-icon img {
      right: 14px !important;
      bottom: 0px !important;
  }
}

.visitors-page .vistors-name {
  display: inline-block;
  vertical-align: middle;
}

@media only screen and (min-width: 320px) and (max-width: 400px) {
  .visitors-page .online-member {
      height: 55px !important;
      width: 55px !important;
  }

  .visitors-page .no-onlinemember {
      height: 55px !important;
      width: 55px !important;
  }

  .visitors-page .new-findppl {
      padding-top: 110px !important;
  }

  .visitors-page .whole-pg2 {
      margin-top: -75px !important;
  }

  .visitors-page .circle-effects:hover .hovereffect-head {
      padding: 4px !important;
  }

  .visitors-page .no-onlinemember:hover .hovereffect-head {
      padding: 4px !important;
  }

  .visitors-page .online-icon img {
      right: 0px !important;
  }

  .visitors-page .online-icon {
      bottom: 46px !important;
  }

  .visitors-page .hovereffect-head h4 {
      font-size: 16px;
  }

  .visitors-page .hovereffect-head h6 {
      font-size: 14px;
  }

  .visitors-page .backgroundbg {
      margin-bottom: 110px;
  }
}

.visitors-page h5.text-overflow {
  white-space: nowrap;
  width: 100%;
  overflow: hidden;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
}

.visitors-page .become-primiuem {
  width: 100%;
  max-width: 280px;
  margin: 0px;
}

@media only screen and (min-width: 401px) and (max-width: 480px) {
  .visitors-page .arch-img img {
      width: 150px;
      height: auto;
  }

  .visitors-page .backgroundbg {
      margin-bottom: 135px;
  }

  .visitors-page .online-member {
      height: 75px !important;
      width: 75px !important;
  }

  .visitors-page .no-onlinemember {
      height: 75px !important;
      width: 75px !important;
  }

  .visitors-page .new-findppl {
      padding-top: 50px !important;
  }

  .visitors-page .whole-pg2 {
      margin-top: -80px !important;
  }

  .visitors-page .unlimited-ppl {
      background-size: 100% !important;
  }

  .visitors-page .circle-effects:hover .hovereffect-head {
      padding: 5px !important;
  }

  .visitors-page .online-icon img {
      width: 10px !important;
      height: auto !important;
  }

  .visitors-page .no-onlinemember:hover .hovereffect-head {
      padding: 5px !important;
  }

  .visitors-page .online-icon {
      bottom: 47px;
  }
}

@media only screen and (min-width: 481px) and (max-width: 768px) {
  .visitors-page .online-member {
      height: 100px !important;
      width: 100px !important;
  }

  .visitors-page .no-onlinemember {
      height: 100px !important;
      width: 100px !important;
  }
}

@media screen and (min-height: 768px) {
  .visitors-page .content-wrapper {
      min-height: 100vh;
  }
}

/*visitor*/

/*visitor-details*/

.visitor-details .homepg {
  width: 76%;
  margin-left: 0px;
}

.visitor-details .visitor-content {
  width: 95%;
}

.visitor-details .banner-close {
  position: absolute;
  right: -14px;
  top: -18px;
  width: 35px;
  height: 35px;
}

.visitor-details #pop1 {
  padding-right: 0px !important;
}

.visitor-details #pop2 {
  padding-right: 0px !important;
}

.visitor-details #pop3 {
  padding-right: 0px !important;
}

.visitor-details #pop1 .modal-dialog {
  width: 100%;
  max-width: 100%;
}

.visitor-details #pop1 .modal-content {
  background-color: transparent;
}

.visitor-details #pop2 .modal-dialog {
  width: 100%;
  max-width: 100%;
}

.visitor-details #pop2 .modal-content {
  background-color: transparent;
}

.visitor-details #pop3 .modal-dialog {
  width: 100%;
  max-width: 100%;
}

.visitor-details #pop3 .modal-content {
  background-color: transparent;
}

.visitor-details .banner-close {
  position: absolute;
  right: 0px;
  top: 0px;
  width: 35px !important;
  height: 35px !important;
  cursor: pointer;
  z-index: 1;
}

.visitor-details .visitor-dropdown .dropdown-item.active,
.visitor-details .dropdown-item:active {
  color: #444444 !important;
  background-color: transparent !important;
}

.visitor-details .banner1 {
  background: url(../img/addpic1.jpg) no-repeat scroll center center/cover;
  height: 500px;
  max-width: 100%;
  overflow: hidden;
  -webkit-transition: all ease 0.5s;
  -o-transition: all ease 0.5s;
  transition: all ease 0.5s;
  cursor: pointer;
}

.visitor-details .banner2 {
  background: url(../img/banner2.jpg) no-repeat scroll center center/cover;
  height: 500px;
  max-width: 100%;
  overflow: hidden;
  -webkit-transition: all ease 0.5s;
  -o-transition: all ease 0.5s;
  transition: all ease 0.5s;
  cursor: pointer;
}

.visitor-details .res-heading-top {
  display: none;
}

.visitor-details .banner3 {
  background: url(../img/cap1.png) no-repeat scroll center center/cover;
  height: 500px;
  max-width: 100%;
  overflow: hidden;
  -webkit-transition: all ease 0.5s;
  -o-transition: all ease 0.5s;
  transition: all ease 0.5s;
  cursor: pointer;
}

.visitor-details #visitor-left {
  background-image: url(../img/back.png);
  background-repeat: no-repeat;
  height: 32px;
  width: 32px;
  top: 50%;
  background-size: cover;
}

.visitor-details #visitor-right {
  background-image: url(../img/back1.png);
  background-repeat: no-repeat;
  height: 32px;
  width: 32px;
  top: 50%;
  background-size: cover;
}

.visitor-details #pop1 img {
  border-radius: 4px;
}

.visitor-details .visitor-dropdown img {
  width: 6px;
  cursor: pointer;
}

.visitor-details .vertically-center {
  height: 100%;
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin: 0 auto;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.visitor-details .interest ul li {
  display: inline-block;
}

.visitor-details .visitor-dropdown {
  width: 5%;
  text-align: right;
}

.visitor-details .visitor-dropdown .block-list {
  visibility: hidden;
  opacity: 0;
  width: 150px;
  background-color: #fff;
  position: absolute;
  -webkit-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  transition: all 0.5s ease;
  right: 5px;
  padding: 5px;
  display: none;
  -webkit-box-shadow: 2px 2px 2px 0px rgba(0, 0, 0, 0.07);
  box-shadow: 2px 2px 2px 0px rgba(0, 0, 0, 0.07);
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  line-height: 27px;
  font-size: 16px;
  text-align: left;
  z-index: 1;
}

.visitor-details .visitor-dropdown:hover .block-list {
  visibility: visible !important;
  opacity: 1 !important;
  display: block !important;
}

.visitor-details ul.block-list li:hover {
  color: #000000;
}

.visitor-details .carousel-indicators li.active {
  background: -webkit-linear-gradient(#000000, #000000) !important;
  border: 2px solid -webkit-linear-gradient(#000000, #000000) !important;
}

.visitor-details .carousel-indicators li {
  width: 10px !important;
  height: 10px !important;
  border-radius: 50% !important;
  border: 2px solid #e1e8eb !important;
  background-color: transparent;
}

@media screen and (max-width: 480px) {
  .visitor-details .visitor-content {
      width: 90%;
  }

  .visitor-details .lives-area {
      white-space: nowrap;
      width: 200px;
      overflow: hidden;
      -o-text-overflow: ellipsis;
      text-overflow: ellipsis;
      margin-bottom: 0px;
      margin-top: 0px;
      display: inline-block;
  }
}

@media screen and (min-height: 768px) {
  .visitor-details .content-wrapper {
      min-height: 100vh;
  }
}

.message-page .homepg {
  width: 76%;
  margin-left: 0px;
  background-color: #ecf0f5;
}

.message-page .msg-pg {
  line-height: 27px;
}

.message-page .vistors-name {
  cursor: pointer;
}

.message-page .sub-header .dropdown-item {
  line-height: 27px;
  cursor: pointer;
}

.message-page .sub-header .dropdown-menu ul li:hover {
  color: #000000;
}

.message-page .sub-header img {
  width: 26px;
}

.message-page .cursor-pointer {
  cursor: pointer;
}

.message-page .message-content img {
  width: 80px;
}

.message-page .message-img {
  text-align: center;
}

.message-page .message-img img {
  width: 70px;
}

.message-page .message-boximg img {
  width: 70px;
}

.message-page .message-content {
  vertical-align: middle;
}

.message-page .message-page-responsivemenu {
  display: block !important;
  width: 100%;
  float: left;
}

.message-page .search-arrow {
  width: 5%;
  text-align: center;
}

.message-page .search-box {
  width: 95%;
}

.message-page .right-part {
  vertical-align: middle;
}

.message-page #appofday {
  padding-right: 0px !important;
}

.message-page .right-part img {
  width: 32px;
}

.message-page .carousel-indicators li.active {
  background: -webkit-linear-gradient(#000000, #000000) !important;
  border: 2px solid -webkit-linear-gradient(#000000, #000000) !important;
}

.message-page .carousel-indicators li {
  width: 10px;
  height: 10px;
  border-radius: 50% !important;
  border: 2px solid #e1e8eb !important;
  background-color: #fff !important;
}

.message-page .dropdown-item.active,
.message-page .dropdown-item:active {
  background-color: transparent;
  color: #444444;
}

.message-page .carousel-caption.caption-con {
  margin-top: 25px !important;
  position: static;
}

.message-page .cancel-app {
  text-align: center;
}

.message-page .message-foot {
  border-radius: 0px 0px 31px 31px;
}

.message-page #appofday .carousel-indicators {
  top: 70%;
  display: none;
}

@media screen and (max-width: 480px) {
  .message-page .message-overflow-text {
      white-space: nowrap;
      max-width: 90px !important;
      width: 100%;
      overflow: hidden;
      -o-text-overflow: ellipsis;
      text-overflow: ellipsis;
  }
}

@media screen and (max-width: 991px) {
  .message-page .vistors-name {
      line-height: 27px;
  }

  .message-page .message-img {
      text-align: center;
  }

  .message-page .search-arrow {
      width: 10%;
      text-align: center;
  }

  .message-page .search-box {
      width: 90%;
  }

  .message-page .message-img.pro-img {
      text-align: center;
  }

  .message-page .message-overflow-text {
      white-space: nowrap;
      max-width: 145px;
      width: 100%;
      overflow: hidden;
      -o-text-overflow: ellipsis;
      text-overflow: ellipsis;
  }

  .message-page .main-sidebar {
      display: none;
  }
}

@media screen and (min-width: 992px) {
  .message-page .message-page-responsivemenu {
      display: none !important;
  }
}

@media screen and (min-height: 768px) {
  .message-page .content-wrapper {
      min-height: 100vh;
  }
}

/*end Message*/

/*Message Conversion*/

.chat-page .homepg {
  width: 76%;
  margin-left: 0px;
}

.chat-page img.dpimg {
  width: 40px;
}

.chat-page .dropdown-item.active,
.chat-page .dropdown-item:active {
  background-color: transparent;
  color: #444444;
}

.chat-page .chat-header {
  position: relative;
}

.chat-page .chating-details {
  position: relative;
  width: 100%;
}

.chat-page .chat-typing {
  position: absolute;
  bottom: 0px;
}

.chat-page .chat-members {
  position: -webkit-sticky;
  position: sticky;
  height: 70px;
  background: #fff;
  width: 100%;
  z-index: 9999;
  top: 0px;
}

.chat-page .user-detailsmsg {
  vertical-align: middle;
  line-height: 16px;
}

.chat-page .chat-icon ul li {
  display: inline-block;
  vertical-align: middle;
}

.chat-page .chat-icon img {
  width: 8px;
}

.chat-page .chat-icon ul li .fa {
  color: #444444ad;
}

.chat-page .sender-part {
  background: #e1e8eb;
}

.chat-page .receiver-part {
  background: #ff346f;
}

.chat-page .msg-right {
  text-align: right;
  color: #fff;
  position: relative;
  margin-right: 30px;
}

.chat-page .receiver-part {
  max-width: 200px;
  width: 100%;
  border-radius: 32px 32px 0px 32px;
}

.chat-page .receiver-part:after {
  width: 32px;
  height: 20px;
  content: "";
  bottom: 26px;
  right: -2px;
  position: absolute;
  background-image: url(../img/chat_right_corner.png);
  background-repeat: no-repeat;
}

.chat-page .user-detailsmsg h5 {
  white-space: nowrap;
  width: 150px;
  overflow: hidden;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
}

.chat-page .chat-typing {
  position: absolute;
  bottom: 100px;
}

.chat-page .chat-box-text {
  height: 50px;
  overflow: auto;
}

.chat-page input[type="file"] {
  display: none;
}

.chat-page .left-area {
  width: 84%;
}

.chat-page .chat-icon ul li.option-block {
  position: relative;
  cursor: pointer;
}

.chat-page .chat-header-details {
  position: absolute;
}

.chat-page #messages-area {
  margin-top: 105px;
}

@media screen and (max-width: 480px) {
  .chat-page .chat-icon ul li {
      padding-left: 14px !important;
  }

  .chat-page .left-area {
      width: 58% !important;
  }

  .chat-page .user-detailsmsg h5 {
      white-space: nowrap;
      width: 65px;
      overflow: hidden;
      -o-text-overflow: ellipsis;
      text-overflow: ellipsis;
  }
}

@media screen and (max-width: 991px) {
  .chat-page .left-area {
      width: 75%;
  }
}

@media screen and (max-height: 850px) {
  .chat-page #messages-area {
      height: 300px;
      overflow-y: auto;
  }
}

@media only screen and (max-width: 1200px) and (min-width: 992px) {
  .chat-page .left-area {
      width: 83%;
  }
}

/*Message Conversion*/

/*friends-details */

.friends-details .homepg {
  width: 76%;
  margin-left: 0px;
}

.friends-details .dropdown-menu.show.rtl {
  left: initial;
  right: 0;
}

.friends-details .dropdown-menu {
  right: 0px !important;
}

.friends-details .banner-close {
  position: absolute;
  right: 0px;
  top: 0px;
  width: 35px !important;
  height: 35px !important;
  cursor: pointer;
  z-index: 1;
}

.friends-details .messenger-icon {
  position: relative;
}

.friends-details .vertically-center {
  height: 100%;
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin: 0 auto;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.friends-details .messagner {
  position: absolute;
  top: -30px;
  right: 15px;
  width: 50px;
}

.friends-details .interest ul li {
  display: inline-block;
}

.friends-details img.sidemeu-icon {
  width: 8px;
  cursor: pointer;
}

.friends-details .visitor-dropdown .dropdown-item.active,
.friends-details .dropdown-item:active {
  color: #444444 !important;
  background-color: transparent !important;
}

.friends-details .visitor-dropdown .block-list {
  visibility: hidden;
  opacity: 0;
  width: 150px;
  background-color: #fff;
  position: absolute;
  -webkit-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  transition: all 0.5s ease;
  right: 5px;
  padding: 5px;
  display: none;
  -webkit-box-shadow: 2px 2px 2px 0px rgba(0, 0, 0, 0.07);
  box-shadow: 2px 2px 2px 0px rgba(0, 0, 0, 0.07);
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  line-height: 27px;
  font-size: 16px;
  text-align: left;
  z-index: 1;
}

.friends-details #pop1 {
  padding-right: 0px !important;
}

.friends-details #pop2 {
  padding-right: 0px !important;
}

.friends-details #pop3 {
  padding-right: 0px !important;
}

.friends-details #pop1 .modal-dialog {
  width: 100%;
  max-width: 100%;
}

.friends-details #pop1 .modal-content {
  background-color: transparent;
}

.friends-details #pop2 .modal-dialog {
  width: 100%;
  max-width: 100%;
}

.friends-details #pop2 .modal-content {
  background-color: transparent;
}

.friends-details #pop3 .modal-dialog {
  width: 100%;
  max-width: 100%;
}

.friends-details #pop3 .modal-content {
  background-color: transparent;
}

.friends-details .visitor-dropdown:hover .block-list {
  visibility: visible !important;
  opacity: 1 !important;
  display: block !important;
}

.friends-details ul.block-list li:hover {
  color: #000000;
}

.friends-details #demo .carousel-item img {
  height: 100%;
  width: 100%;
}

.friends-details #pop1 {
  padding-right: 0px !important;
}

.friends-details #pop2 {
  padding-right: 0px !important;
}

.friends-details #pop3 {
  padding-right: 0px !important;
}

.friends-details .carousel-indicators li.active {
  background: -webkit-linear-gradient(#000000, #000000) !important;
  border: 2px solid -webkit-linear-gradient(#000000, #000000) !important;
}

.friends-details .carousel-indicators li {
  width: 10px !important;
  height: 10px !important;
  border-radius: 50% !important;
  border: 2px solid #e1e8eb !important;
  background-color: transparent;
}

.friends-details .frnds-bg1 {
  background: url(../img/f4.jpg) no-repeat scroll center center/cover;
  height: 500px;
  max-width: 100%;
  overflow: hidden;
  -webkit-transition: all ease 0.5s;
  -o-transition: all ease 0.5s;
  transition: all ease 0.5s;
  cursor: pointer;
}

.friends-details .frnds-bg4 {
  background: url(../img/f1.jpg) no-repeat scroll center center/cover;
  height: 500px;
  max-width: 100%;
  overflow: hidden;
  -webkit-transition: all ease 0.5s;
  -o-transition: all ease 0.5s;
  transition: all ease 0.5s;
  cursor: pointer;
}

.friends-details .frnds-bg2 {
  background: url(../img/f3.jpg) no-repeat scroll center center/cover;
  height: 500px;
  max-width: 100%;
  overflow: hidden;
  -webkit-transition: all ease 0.5s;
  -o-transition: all ease 0.5s;
  transition: all ease 0.5s;
  cursor: pointer;
}

.friends-details .frnds-bg3 {
  background: url(../img/f2.jpg) no-repeat scroll center center/cover;
  height: 500px;
  max-width: 100%;
  overflow: hidden;
  -webkit-transition: all ease 0.5s;
  -o-transition: all ease 0.5s;
  transition: all ease 0.5s;
  cursor: pointer;
}

.friends-details #zoom-left {
  background-image: url(../img/back.png);
  background-repeat: no-repeat;
  height: 32px;
  width: 32px;
  top: 50%;
  background-size: cover;
}

.friends-details #zoom-right {
  background-image: url(../img/back1.png);
  background-repeat: no-repeat;
  height: 32px;
  width: 32px;
  top: 50%;
  background-size: cover;
}

.friends-details .visitor-content {
  width: 95%;
}

.friends-details .visitor-dropdown {
  width: 5%;
  text-align: right;
}

.friends-details .loder-icon {
  position: relative;
}

.friends-details .loader.available {
  opacity: 0;
}

.friends-details .loader {
  position: absolute;
  left: 0px;
  top: 0px;
  z-index: 99;
  width: 100%;
  background-color: #f7f7f7;
  height: 100%;
}

.friends-details .loading__dot {
  position: relative;
  left: 45%;
  top: 40%;
  color: #000000;
}

.friends-details .loading__dot.available {
  opacity: 0;
}

@keyframes blink {
  50% {
      color: transparent;
  }
}

.friends-details .loading__dot {
  -webkit-animation: 1s blink infinite;
  animation: 1s blink infinite;
  font-size: 75px;
  text-align: center;
}

.friends-details .loading__dot:nth-child(2) {
  -webkit-animation-delay: 250ms;
  animation-delay: 250ms;
}

.friends-details .loading__dot:nth-child(3) {
  -webkit-animation-delay: 500ms;
  animation-delay: 500ms;
}

@media screen and (max-width: 360px) {
  .friends-details .messagner {
      width: 26px;
  }
}

@media screen and (max-width: 991px) {
  .friends-details .frnds-bg1 {
      -o-object-fit: cover;
      object-fit: cover;
  }
}

@media only screen and (max-height: 576px) and (min-height: 320px) {
  .friends-details .frnds-bg2 {
      height: 320px;
  }

  .friends-details .frnds-bg1 {
      height: 320px;
  }

  .friends-details .frnds-bg3 {
      height: 320px;
  }
}

@media screen and (min-height: 768px) {
  .friends-details .content-wrapper {
      min-height: 100vh;
  }
}

/*friends-details */

/*Friend requestdetails*/

.friend-request-details .homepg {
  width: 76%;
  margin-left: 0px;
}

.friend-request-details .interest ul li {
  display: inline-block;
}

.friend-request-details .res-heading-top {
  display: none;
}

.friend-request-details .visitor-dropdown .block-list {
  visibility: hidden;
  opacity: 0;
  width: 150px;
  background-color: #fff;
  position: absolute;
  -webkit-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  transition: all 0.5s ease;
  right: 5px;
  padding: 5px;
  display: none;
  -webkit-box-shadow: 2px 2px 2px 0px rgba(0, 0, 0, 0.07);
  box-shadow: 2px 2px 2px 0px rgba(0, 0, 0, 0.07);
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  line-height: 27px;
  font-size: 16px;
  text-align: left;
  z-index: 1;
}

.friend-request-details .visitor-dropdown .dropdown-item.active,
.friend-request-details .dropdown-item:active {
  color: #444444 !important;
  background-color: transparent !important;
}

.friend-request-details .fixed-position {
  position: relative;
  height: 70px;
  width: 100%;
  z-index: 99;
  bottom: 70px;
}

.friend-request-details .people-like {
  position: fixed;
  bottom: 190px;
  width: 100%;
  text-align: center;
  left: 50%;
}

.friend-request-details .people-like ul {
  position: fixed;
}

.friend-request-details .visitor-dropdown img {
  width: 8px;
  cursor: pointer;
}

.friend-request-details .banner-close {
  position: absolute;
  right: 0px;
  top: 0px;
  width: 35px !important;
  height: 35px !important;
  cursor: pointer;
  z-index: 1;
}

.friend-request-details .vertically-center {
  height: 100%;
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin: 0 auto;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.friend-request-details .view-profile {
  background: url(../img/banner1.jpg) no-repeat scroll center center/cover;
  height: 500px;
  max-width: 100%;
  overflow: hidden;
  -webkit-transition: all ease 0.5s;
  -o-transition: all ease 0.5s;
  transition: all ease 0.5s;
  cursor: pointer;
}

.friend-request-details .view-profile1 {
  background: url(../img/detail.jpg) no-repeat scroll center center/cover;
  height: 500px;
  max-width: 100%;
  overflow: hidden;
  -webkit-transition: all ease 0.5s;
  -o-transition: all ease 0.5s;
  transition: all ease 0.5s;
  cursor: pointer;
}

.friend-request-details .view-profile2 {
  background: url(../img/pic1.jpg) no-repeat scroll center center/cover;
  height: 500px;
  max-width: 100%;
  overflow: hidden;
  -webkit-transition: all ease 0.5s;
  -o-transition: all ease 0.5s;
  transition: all ease 0.5s;
  cursor: pointer;
}

.friend-request-details .visitor-dropdown:hover .block-list {
  visibility: visible !important;
  opacity: 1 !important;
  display: block !important;
}

.friend-request-details .carousel-indicators li {
  width: 10px !important;
  height: 10px !important;
  border-radius: 50% !important;
  border: 2px solid #e1e8eb !important;
  background-color: transparent;
}

.friend-request-details .carousel-indicators li.active {
  background: -webkit-linear-gradient(#000000, #000000) !important;
  border: 2px solid -webkit-linear-gradient(#000000, #000000) !important;
}

.friend-request-details #left-addpeople {
  background-image: url(../img/back.png);
  background-repeat: no-repeat;
  height: 32px;
  width: 32px;
  top: 50%;
  background-size: cover;
}

.friend-request-details #right-addpeople {
  background-image: url(../img/back1.png);
  background-repeat: no-repeat;
  height: 32px;
  width: 32px;
  top: 50%;
  background-size: cover;
}

.friend-request-details ul.block-list li:hover {
  color: #000000;
}

.friend-request-details .people-like ul li {
  display: inline-block;
}

.friend-request-details .visitor-content {
  width: 95%;
}

.friend-request-details .visitor-dropdown {
  width: 5%;
  text-align: right;
}

.friend-request-details .messenger-icon {
  position: relative;
}

.friend-request-details .messagner {
  position: absolute;
  top: -30px;
  right: 15px;
  width: 50px;
}

.friend-request-details #pop1 {
  padding-right: 0px !important;
}

.friend-request-details #pop2 {
  padding-right: 0px !important;
}

.friend-request-details #pop3 {
  padding-right: 0px !important;
}

.friend-request-details #pop1 .modal-dialog {
  width: 100%;
  max-width: 100%;
}

.friend-request-details #pop1 .modal-content {
  background-color: transparent;
}

.friend-request-details #pop2 .modal-dialog {
  width: 100%;
  max-width: 100%;
}

.friend-request-details #pop2 .modal-content {
  background-color: transparent;
}

.friend-request-details #pop3 .modal-dialog {
  width: 100%;
  max-width: 100%;
}

.friend-request-details #pop3 .modal-content {
  background-color: transparent;
}

@media screen and (min-width: 320px) and (max-width: 991px) {
  .friend-request-details .people-like {
      position: fixed;
      bottom: 190px;
      width: 200PX;
      text-align: center;
      left: 0;
      right: 0;
      margin: 0 auto;
  }

  .friend-request-details .main-sidebar {
      display: none;
  }
}

/*Friend requestdetails*/

/*Edit photo*/

.edit-photo-page .homepg {
  width: 76%;
  margin-left: 0px;
}

.edit-photo-page .edit-photo-option {
  cursor: pointer;
}

.edit-photo-page .edit-profilephoto img.img-fluid {
  border-radius: 4px !important;
}

.edit-photo-page .res-heading-top {
  display: none;
}

.edit-photo-page #editphoto-carsousel .carousel-indicators {
  bottom: 35px;
}

.edit-photo-page .dropdown-item.active,
.edit-photo-page .dropdown-item:active {
  background-color: transparent !important;
  color: #000000;
}

.edit-photo-page .edit-option {
  position: relative;
}

.edit-photo-page .option-list-photo {
  position: absolute;
  right: 15px;
  top: -30px;
  z-index: 2;
}

.edit-photo-page .carousel-indicators li.active {
  background: -webkit-linear-gradient(#000000, #000000) !important;
  border: 2px solid -webkit-linear-gradient(#000000, #000000) !important;
}

.edit-photo-page .carousel-indicators li {
  width: 10px !important;
  height: 10px !important;
  border-radius: 50% !important;
  border: 2px solid #e1e8eb !important;
  background-color: transparent;
}

.edit-photo-page .edit-img1 {
  vertical-align: middle;
}

.edit-photo-page .edit-img1 img {
  vertical-align: middle;
  width: 40px;
  height: 40px;
}

.edit-photo-page img.edit-img1 {
  width: 40px;
  height: 40px;
}

.edit-photo-page .option-list-photo .dropdown-menu.show {
  top: 0px !important;
  left: -130px !important;
  border-radius: 32px 32px 0px 32px;
}

.edit-photo-page .option-list-photo .dropdown-item:focus,
.edit-photo-page .dropdown-item:hover {
  background-color: transparent;
  color: #000000;
}

.edit-photo-page .custom-file-upload {
  cursor: pointer;
  color: white;
  text-align: center;
}

.edit-photo-page input[type="file"] {
  display: none;
}

@media screen and (max-width: 768px) {
  .edit-photo-page .option-list-photo .dropdown-menu.show {
      left: -45px !important;
  }

  .edit-photo-page .option-list-photo {
      top: 0px;
  }
}

/*Edit photo*/

/*Edit Profile*/

.edit-profile-page .homepg {
  width: 76%;
  margin-left: 0px;
}

.edit-profile-page .form-control {
  border-radius: 0px !important;
}

.edit-profile-page .form-control:focus {
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}

.edit-profile-page .user-input-wrp {
  position: relative;
}

.edit-profile-page .user-input-wrp .inputText {
  width: 100%;
  outline: none;
  border: none;
  border-bottom: 1px solid #e1e8eb;
}

.edit-profile-page .user-input-wrp .inputText:invalid {
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}

.edit-profile-page .user-input-wrp .inputText:focus {
  border-color: #000000;
  border-width: medium medium 2px;
}

.edit-profile-page .user-input-wrp .floating-label {
  position: absolute;
  pointer-events: none;
  top: 18px;
  left: 0px;
  -webkit-transition: 0.2s ease all;
  -o-transition: 0.2s ease all;
  transition: 0.2s ease all;
}

.edit-profile-page .user-input-wrp input:focus~.floating-label,
.edit-profile-page .user-input-wrp input:not(:placeholder-shown)~.floating-label {
  top: 0px;
  left: 0px;
  font-size: 13px;
  opacity: 1;
}

.edit-profile-page .right-bg {
  margin-bottom: 40px;
}

.edit-profile-page .edit-profile-width {
  max-width: 700px;
  width: 100%;
}

.edit-profile-page .res-heading-top {
  display: none;
}

.edit-profile-page .radio-btn {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
}

.edit-profile-page .radio-btn input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

.edit-profile-page .checkmark {
  position: absolute;
  top: 2px;
  left: 0;
  height: 20px;
  width: 20px;
  border: 2px solid #444444;
  border-radius: 50%;
}

.edit-profile-page .radio-btn input:checked~.checkmark {
  border: 2px solid #000000;
}

.edit-profile-page .checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

.edit-profile-page .radio-btn input:checked~.checkmark:after {
  display: block;
}

.edit-profile-page .radio-btn .checkmark:after {
  top: 4px;
  left: 4px;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: -webkit-linear-gradient(#000000, #000000);
}

/*end Edit Profile*/

/*welcome-page*/

.welcome-page .login-content img {
  max-width: 200px;
  width: 100%;
}

.welcome-page .login-bg {
  position: fixed;
  background-image: url(../img/howzu.png);
  background-position: top center;
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 100vh;
  height: 100%;
  width: 100%;
  background: -webkit-linear-gradient(#000000, #000000);
}

.facebook-link {
  max-width: 600px;
  line-height: 32px;
}

a.facebook-link:hover,
.welcome-page a.facebook-link:active,
.welcome-page a.facebook-link:focus {
  color: #fff !important;
}

.link-redireaction {
  max-width: 600px;
  line-height: 32px;
}

.welcome-page .link-redireaction:hover {
  color: #444444;
}

.welcome-page .loder-icon {
  position: relative;
}

.welcome-page .loader.available {
  opacity: 0;
}

.welcome-page .loader {
  position: fixed;
  left: 0px;
  top: 0px;
  z-index: 99;
  width: 100%;
  background-color: #fff;
  height: 100%;
}

.welcome-page .loading__dot {
  position: relative;
  left: 45%;
  top: 40%;
  color: #000000;
}

.welcome-page .loading__dot.available {
  opacity: 0;
}

@keyframes blink {
  50% {
      color: transparent;
  }
}

.welcome-page .loading__dot {
  -webkit-animation: 1s blink infinite;
  animation: 1s blink infinite;
  font-size: 75px;
  text-align: center;
}

.welcome-page .loading__dot:nth-child(2) {
  -webkit-animation-delay: 250ms;
  animation-delay: 250ms;
}

.welcome-page .loading__dot:nth-child(3) {
  -webkit-animation-delay: 500ms;
  animation-delay: 500ms;
}

.welcome-page .welcome-pg {
  max-width: 600px;
  margin: 0 auto;
  display: table;
  vertical-align: middle;
  width: 100%;
}

.welcome-page #welcome .carousel-indicators li {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: #fff;
}

.welcome-page #welcome .carousel-indicators .active {
  background-color: transparent;
  border: 2px solid #fff;
  margin-top: 10px !important;
  height: 6px !important;
  width: 6px !important;
}

.welcome-page .step3 {
  text-align: left;
}

.white-border {
  border: 2px solid #fff;
  cursor: pointer;
}

.welcome-page .inner-login {
  position: relative;
}

.welcome-page .signin-tab {
  text-align: left;
}

.welcome-page .transparent-bg {
  background-color: transparent;
}

.welcome-page .center-logo {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  min-height: 100vh;
}

.welcome-page .v-alignlogo {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  min-height: 100vh;
}

@media screen and (max-height: 600px) {
  .welcome-page .login-bg {
      position: relative;
  }
}

@media screen and (min-height: 768px) {
  .welcome-page .content-wrapper {
      min-height: 100vh;
  }
}

/*welcome-page*/

/*signin-page*/

input#file-upload {
  display: none;
}

label.custom-file-upload {
  cursor: pointer;
}

input.form-control.py-2.border-none.search-noborder:focus {
  border-bottom: none !important;
}

.sign-bg-color {
  width: 100%;
  float: left;
  background-color: #fff;
  min-height: 100vh;
}

.user-input-wrp {
  position: relative;
}

.user-input-wrp .inputText {
  width: 100%;
  outline: none;
  border: none;
  border-bottom: 1px solid #e1e8eb;
  background-color: transparent;
}

.user-input-wrp .inputText:invalid {
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}

.user-input-wrp .inputText:focus {
  border-color: #000000;
  border-width: medium medium 2px;
}

.user-input-wrp .floating-label {
  position: absolute;
  pointer-events: none;
  top: 18px;
  left: 0px;
  -webkit-transition: 0.2s ease all;
  -o-transition: 0.2s ease all;
  transition: 0.2s ease all;
}


.tab-content .form-control {
  background-color: transparent !important;
  border-radius: 0px !important;
  color: #444444;
}

.login-bg {
  background-position: top center;
  background-repeat: no-repeat;
  background-size: cover;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  min-height: 100vh;
  height: 100%;
  width: 100%;
}

.inner-login {
  position: relative;
}

.sigin-page .center-logo {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}

.sigin-page .v-alignlogo {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  width: 100%;
}

.form-control:focus {
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}

.res-heading-top1 {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.sigin-page .left-logo img {
  width: 25px;
}

.sigin-page .signin-content {
  width: 100%;
  max-width: 500px;
  margin: 0 auto;
}

.sigin-page .signin-tab ul li {
  width: 50%;
  text-align: center;
}

.sigin-page ul.signin-tab {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
}

.sigin-page .signin-tab ul li a {
  color: #444444;
}

.sigin-page .signin-tab .nav-tabs .nav-item.show .nav-link,
.sigin-page .nav-tabs .nav-link.active {
  border-bottom: 2px solid #000000 !important;
  border: none;
  color: #000000;
  background-color: transparent;
}

.sigin-page .signin-tab .nav-tabs .nav-link:focus,
.sigin-page .nav-tabs .nav-link:hover {
  background: transparent;
  border: transparent;
}

.sigin-page .fade:not(.show) {
  opacity: 1;
}

.sigin-page .signin-content .form-control:focus {
  border-bottom: 2px solid #000000 !important;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
  border-radius: 0px;
}

.sigin-page h6.wizards1-pop1 {
  white-space: nowrap;
  width: 100%;
  overflow: hidden;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
}

.sigin-page .radio-btn {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
}

.sigin-page .powered-by {
  line-height: 40px;
}

.sigin-page .radio-btn input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

.sigin-page .checkmark {
  position: absolute;
  top: 2px;
  left: 0;
  height: 20px;
  width: 20px;
  border: 2px solid #444444;
  border-radius: 50%;
}

.sigin-page .radio-btn input:checked~.checkmark {
  border: 2px solid #000000;
}

.sigin-page .checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

.sigin-page .radio-btn input:checked~.checkmark:after {
  display: block;
}

.sigin-page .radio-btn .checkmark:after {
  top: 4px;
  left: 4px;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: -webkit-linear-gradient(#000000, #000000);
}

.sigin-page ol.progtrckr {
  display: none;
}

.sigin-page .step1-icon-bg {
  background: #939a9c;
}

.sigin-page .footer-buttons {
  width: 90%;
  margin: 0 auto;
}

.sigin-page .btn-group-lg>.btn,
.sigin-page .btn-lg {
  background: -webkit-linear-gradient(#000000, #000000);
  border: 1px solid #000000;
  border-radius: 32px;
  margin-bottom: 20px;
  margin-top: 20px;
  font-size: 16px;
}

.sigin-page .add-photos {
  background-color: #0081ff99;
  border-radius: 32px 32px 0px 0px;
  color: #fff;
  height: 280px;
  position: relative;
  margin-bottom: 85px;
}

.photo-rotation img {
  width: 200px;
  height: 200px;
  -webkit-transform: rotate(-25deg);
  -ms-transform: rotate(-25deg);
  transform: rotate(-25deg);
  left: 0px;
  position: absolute;
  top: 115px;
  right: 0px;
  margin: 0 auto;
  border: 2px dashed -webkit-linear-gradient(#000000, #000000);
  padding: 5px;
  object-fit: cover;
}

.sigin-page .sigin-page .step2 .btn-group-lg>.btn,
.sigin-page .sigin-page .btn-lg {
  margin-top: 70px !important;
}

.sigin-page .footer-buttons .btn-group-lg>.btn,
.sigin-page .sigin-page .btn-lg .btn.focus,
.sigin-page .btn:active {
  background: -webkit-linear-gradient(#000000, #000000) !important;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
  border: none !important;
}

.sigin-page .step3 .form-control:focus.search-noborder {
  border: none !important;
}

.sigin-page .btn-primary.focus,
.sigin-page .btn-primary:focus {
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}

.sigin-page .btn.search-btn {
  background-color: #ffff;
  padding: 0px;
}

.sigin-page .btn.search-btn img {
  width: 32px;
}

.sigin-page .interst-option {
  display: block;
  position: relative;
  cursor: pointer;
}

.sigin-page .interst-option input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.sigin-page .interst-option input:checked~.checkmark1 {
  background-image: url(../img/tick.png);
  background-repeat: no-repeat;
  background-position: right center;
  background-size: 26px;
  color: #000000;
  position: absolute;
  right: 0px;
  width: 100%;
  height: 30px;
}

.sigin-page .signin-content .sub-header {
  padding-left: 0px !important;
}

.sigin-page .interst-option input:checked~.checkmark1:after {
  display: block;
}

.sigin-page #signup .model {
  padding-left: 0px !important;
}

.sigin-page .vertically-center {
  height: 100%;
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin: 0 auto;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.sigin-page .iconimg img {
  background: #d7e1e4;
  border-radius: 50%;
  padding: 15px;
  cursor: pointer;
}

.sigin-page img.navigation--active {
  background: -webkit-linear-gradient(#000000, #000000);
}

.sigin-page .close {
  opacity: 1;
}

.sigin-page img.close-size {
  width: 15px;
}

@media screen and (max-width: 768px) {
  .sigin-page .max-mobile-header {
      display: none;
  }

  .sigin-page .back-arrow img {
      width: 25px;
  }

  .sigin-page .bottom-part {
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      justify-content: center;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
  }
}

@media screen and (min-width: 768px) {
  .sigin-page .mobile-header {
      display: none;
  }
}

/*signinend*/

/*Help-page*/

.Help-page .help-bg {
  width: 100%;
  float: left;
  background: #fff;
}

.Help-page .homepg {
  width: 100%;
  background-color: transparent;
  margin-left: 0px;
}

.Help-page .fade:not(.show) {
  opacity: 1;
}

/*.Help-page .help-left.nav { 
  display: block !important;
}*/   

.Help-page .help-left .nav-tabs .nav-item.show .nav-link,
.Help-page .nav-tabs .nav-link.active {
  color: #000000 !important;
  font-family: 'QanelasSoft-Bold';
}

.Help-page .help .nav-tabs .nav-item {
  margin-bottom: 10px !important;
}

.Help-page .btn-primary.dropdown-toggle.help-links {
  background: transparent;
  line-height: 35px;
  color: #444;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
  border-radius: 0px;
}

.Help-page .dropdown-item.active,
.Help-page .dropdown-item:active {
  background-color: transparent;
  color: #444444;
}

.Help-page .btn-primary.dropdown-toggle.help-links:hover,
.Help-page active,
.Help-page focus {
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
  color: #444;
}

.Help-page .help-left-r .dropdown-menu span.nav {
  display: block !important;
}

.Help-page .help-left-r .dropdown-menu {
  -webkit-box-shadow: 0 4px 12px -6px #0003;
  box-shadow: 0 4px 12px -6px #0003;
  border: none !important;
}

.Help-page .help-left-r ul.dropdown-menu .nav-tabs .nav-link.active {
  color: #444 !important;
  font-weight: normal !important;
}

.Help-page .help-leftpart {
  width: 30%;
}

.Help-page .help-right {
  width: 70%;
}

.Help-page .appkodes-power {
  text-align: right;
  line-height: 40px;
}

.Help-page .nav-pills .nav-link.active,
.Help-page .nav-pills .show>.nav-link {
  background-color: transparent;
  color: #444444;
  text-align: center;
}

.Help-page .help-left-r ul li.nav-item.dropdown {
  width: 100%;
}

.Help-page .help-left-r ul li.nav-item.dropdown a.nav-link.dropdown-toggle {
  text-align: center;
  cursor: pointer;
}

.Help-page .help-left-r ul li.nav-item.dropdown a.nav-link.dropdown-toggle:hover {
  background: none !important;
}

.Help-page .help-left-r .dropdown-menu.show {
  width: 100%;
}

.Help-page .dropdown-toggle::after {
  vertical-align: middle;
}

@media (min-width: 320px) and (max-width: 991px) {
  .Help-page .help-right {
      width: 100%;
  }

  .Help-page .profile-logo1 {
      text-align: center;
  }

  .Help-page .profile-header-top1 {
      text-align: center;
  }
}

@media screen and (min-height: 768px) {
  .Help-page .content-wrapper {
      min-height: 100vh;
  }
}

/*end Help-page*/

/*Audio call*/

.audio-call .audio-bg {
  position: fixed;
  background-image: url(../img/f4.jpg);
  background-position: top center;
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 100%;
  height: 100%;
  width: 100%;
}

.audio-call .inner-login {
  position: relative;
}

.audio-call .top-part {
  position: fixed;
  top: 50px;
  text-align: center;
  width: 100%;
}

.audio-call .top-part img {
  width: 75px;
  border-radius: 50%;
}

.audio-call .bottom-part {
  position: fixed;
  bottom: 30px;
  text-align: center;
  width: 100%;
}

.audio-call .calling-option ul li {
  cursor: pointer;
  border-radius: 50%;
  width: 70px;
  height: 70px;
  font-size: 36px;
}

.audio-call .calling-option ul li.microphone {
  background-color: #eeeeee4d;
}

.audio-call .calling-option ul li.phone {
  background-color: red;
}

.audio-call .calling-option ul li.speaker {
  background-color: #00000080;
}

.audio-call .calling-option ul li span {
  vertical-align: middle;
}

.audio-call .calling-option ul li span .fa {
  vertical-align: middle;
}

/*Audio call*/

/*Forgot*/

#forgot .form-control {
  background-color: transparent;
  border-radius: 0px;
  color: #444444;
}

.signin-content .sub-header {
  padding-left: 0px !important;
}

/*Forgot*/

/*Message*/

.v-alignlogo {
  height: 100vh;
}

.messageHeightfix {
  height: 110vh;
}

.pb20 {
  padding-bottom: 30px;
  margin-bottom: 30px;
}

.left-sidemenu .sidebar ul.sidebar-menu li {
  padding: 5px 20px;
  line-height: 37px;
}

.left-sidemenu .messagecount {
  top: 10px;
  right: 13px
}

.left-sidemenu .visitorscount {
  top: 10px;
  right: 13px;
}

.left-sidemenu .rf {
  top: 10px;
  right: 13px;
}

img.logo-size {
  width: 150px !important;
}

.new-people img.logo-size {
  width: 150px !important;
}

.visitors-page img.logo-size {
  width: 150px !important;
}

.posFixed {
  position: fixed;
}

@media (max-width: 991px) {
  body {
      background: #fff;
  }
 
}

.center-logo .v-alignlogo {
  background: transparent;
}

.v-alignlogo {
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #F5F7FA;
}

.profile-header-top {
  width: 100%;
  padding: 5px;
}

.profile-page .edit-img {
  margin: 10px 0px 20px;
}

.greynewBg {
  background: #F5F7FA;
}

.left-logo {
  padding-top: 3px;
  padding-left: 6px;
}

.rightFilter {
  padding-right: 6px;
}

/*New*/

a {
  color: #444;
}

.home {
  display: flex;
  height: 100vh;
}

.leftSideMenu {
  flex: 0 0 auto;
  width: 320px;
  overflow-y: auto;
  min-height: 100vh;
  border-right: 1px solid #ececec;
  z-index: 4;
  box-shadow: -4px 0px 15px rgba(0, 0, 0, 0.15);
}

.rightSideContent {
  flex: 1 1 auto;
  height: 100vh;
  overflow-y: auto;
  overflow-x: hidden;
}

/*New SideBar*/

.sidebarWrap {
  font-family: QanelasSoft-Regular;
  display: flex;
  flex-direction: column;
  height: 100vh;
  font-size: 15px;   
}

.sidebarWrap a {
  color: #444;
}

.sidebarWrap a:hover {
  color: #000000;
}

.sideProfileBody {
  padding: 15px 15px;
  box-shadow: 0 7px 0px -6px #F5F7FA;
}

.sideLogoSection {
  padding: 10px 10px 5px;
}

.sideListBody {
  padding: 15px 15px;
  box-shadow: 0 7px 0 -6px #f5f7fa;
}

.sideLogo {
  width: 150px;
}

.pFHidden {
  overflow: hidden;
}

.sideListBody .media {
  padding: 10px 0px;
}

.currentUserDP {
/*  background: url(../img/profile.JPG) no-repeat scroll center center/cover;*/
  height: 50px;
  width: 50px;
  border-radius: 50%;
  cursor: pointer;
}

.settingsIco {
  background: url(../img/setting.png) center/cover;
  height: 25px;
  width: 25px;
  cursor: pointer;
}

.settingsIcoRose {
  background: url(../img/setting1.png) center/cover;
  height: 25px;
  width: 25px;
  cursor: pointer;
}

.backIco {
  background: url("../img/back.png") center/cover;
  height: 20px;
  width: 20px;
  cursor: pointer;
}

.sidebarIcoPos {
  height: 25px;
  width: 25px;
  margin-left: 11px;  
  cursor: pointer;
}

.nearYouico {
  background: url(../img/near_c.png) center/cover; 
}

.findNewPeopleIco {
  background: url(../img/card_view_c.png) center/cover;  
}

.subscription {
  background: url(../img/subscription.png) center/cover;  
}

.visitorsIco {
  background: url(../img/visitorsNew.png) center/cover;      
}

.messageIco {
  background: url(../img/message.png) center/cover;
}

.feedIco {
  background: url(../img/message_c.png) center/cover;  
}

.friendsIco {
  background: url(../img/matches.png) center/cover;
  height: 25px;  
}

.friendReqIco {
  background: url(../img/super_like_profile.png) center/cover; 
}

.likesReqIco {
  background: url(../img/liked.png) center/cover;
}

.helpIco {
  background: url(../img/help.png) center/cover; 
}

.visitorsNotifierIco {
  background: url(../img/visitor_bg.png) center/cover;
}

.messageNotifierIco {
  background: url(../img/message_bg.png) center/cover;
}

.friendReqNotifier {
  background: url(../img/request_bg.png) center/cover;
}
 
.filterIcon {
  background: url(../img/filter.png) center/cover;
  margin-left: 0px;
}

.boostIcon {
  background: url(../img/boost-64.png) center/cover; 
  margin-left: 0px; 
  border: none;
  box-shadow: none; 
}

.sideBarToggler {
  background: url(../img/nav-64.png) center/cover;
  margin-left: 0px;
}

.NotifiersizePos {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-size: 12px;
}

.sidebarLastContent {
  padding: 15px 15px;
  align-self: center;
}

.btn-common {
  background: -webkit-linear-gradient(#000000, #000000) !important;
  color: #fff !important;
  font-size: 14px;
  border: none;
  border-radius: 30px;
  padding: 10px 30px;
  cursor: pointer;
  text-align: center;
}

.rightContents {
  display: flex;
  min-height: 100vh;
  flex-direction: column;
  background: white;
}

.contentSection {
  margin: 20px 0px;
  padding: 0px 15px;
}

.ContentHeaderSection {
  background: #fff;
  padding: 10px 15px;
  position: sticky;
  top: 0;
  border-bottom: 1px solid #ececec;
  z-index: 2;
}

.footerSection {
  position: sticky;
  bottom: 0;
  z-index: 2;
}

.footerSty {
  padding: 15px 25px;
  width: 100%;
  height: auto;
  background: #ffffff;
  word-wrap: break-word;
  border-top: 1px solid #ececec;
  font-size: 14px;
  position: sticky;
  bottom: 0;
}

.footerStyleFlex {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

@media(max-width:550px) {
  .footerStyleFlex {
      justify-content: center;
  }
}

.sidebarResp {
  padding: 0px !important;
  background: #fff !important;
}

.sidebarResp h1 {
  display: none !important;
}

.sidebarResp li {
  padding: 0px !important;
}

.sidebarResp>aside>nav {
  max-width: 320px !important;
  min-height: 100vh;
  overflow-y: auto;
  box-shadow: none !important;
}

.sidebarResp ul li {
  background: transparent !important;
}

.sidebarResp ul li:hover {
  background: transparent !important;
}

.settingInterestOverflow {
  height: 100%;
  max-height: 260px;
  overflow-y: auto;
  overflow-x: hidden;
}

@media (max-width:600px) {
  .settingInterestOverflow {
      justify-content: space-evenly !important;
  }
}

.footerSty a {
  color: #444;
}

.filter-page {
  font-family: 'QanelasSoft-Regular';
}

.setting-page {
  font-family: 'QanelasSoft-Regular';
}

.media-body a.active {
  color: #000000;
  font-family: 'QanelasSoft-Bold';
}

.ContentHeaderSection .sideLogo {
  width: 120px;
}

aside {
  z-index: 200 !important;
}

.helpPageHolder {
  position: absolute;
  top: 0;
  left: 0;
  background: white;
  min-height: 100vh;
  z-index: 5;
  width: 100%;
}

#signup {
  padding-right: 5px !important;
}





.carousel-indicators {
  bottom: 10px;
  z-index: 1;
}

.chat-page {
  background: white;
}

.messageDPimg {
  height: 50px;
  width: 50px;
  border-radius: 50%;
}

.chatLeftArrow {
  width: 25px;
  height: 25px;
}

.rightSideThree {
  width: 6px;
  margin-top: -6px;
}

.chatOptions {
  padding: 10px;
  cursor: pointer;
}

.chat-page .messages {
  padding: 15px 10px 15px 10px;
}

.dropdown-toggle::after {
  display: none;
}

.chat-body {
  height: 60vh;
  overflow-y: scroll;
}

@media(max-width:500px) {
  .home-page .slick-prev {
      left: 16%
  }

  .home-page .slick-next {
      right: 15%
  }
}

@media(max-width:360px) {
  .home-page .slick-prev {
      left: 12%
  }

  .home-page .slick-next {
      right: 11%
  }
}

.friends-details,
.friends-page,
.message-page,
.friend-request {
  padding: 10px
}

.navHeadText {
  color: #000000;
  font-size: 22px;
}

.inputText {
  height: 30px;
}

@media (max-width:480px) {
  .people-near .circle-ripple img {
      width: 200px;
      height: 200px;
      border: 3px solid #fff;
  }

  .people-near .circle-ripple {
      width: 200px;
      height: 200px;
      border-radius: 50%;
  }
}

.backGlow {
  padding: 10px;
  border-radius: 50%;
  transition: all 0.2s linear;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 45px;
  width: 45px;
}

.iconHolder:hover .backGlow {
  background-color: whitesmoke;
}

.bgT {
  background: transparent !important
}

.people-near {
  display: flex;
  justify-content: center;
  height: 100vh;
  align-items: center;
}

.settingsPageWidth {
  display: flex;
  flex-direction: column;
  padding: 15px;
  min-height: 89vh;
  justify-content: center;
  align-items: center;
}

.setButton {
  width: 100%;
  background: white;
  border: none;
  padding: 10px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.15);
  cursor: pointer;
}

.ProfileSpace {
  width: 100%;
  max-width: 500px;
  margin: 0px auto;
}

.androidIco {
  background: url(../img/androidIco.png) center/cover;
  height: 25px;
  width: 25px;
  cursor: pointer;
}

.iosIco {
  background: url(../img/iosIco.png) center/cover;
  height: 25px;
  width: 25px;
  cursor: pointer;
}

.stickyProfileHead {
  position: sticky;
  width: 100%;
  top: 0;
  z-index: 2;
}

.profilePageDp {
  height: 180px;
  width: 180px;
  border-radius: 50%;
}

.posRel {
  position: relative
}

.editProfileIcon {
  background: -webkit-linear-gradient(#000000, #000000);
  border-radius: 50%;
  position: absolute;
  height: 40px;
  width: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  bottom: 5px;
  right: 10px;
}

.editProfileIcon img {
  height: 17px;
}

.rightArrow {
  background: url(../img/front1.png) center/cover;
  height: 15px;
  width: 15px;
  cursor: pointer;
}

.pointCurs {
  cursor: pointer;
}

.dpPicHolder {
  width: 400px;
  height: 400px;
}

.commonLogoC {
  width: 120px !important;
}

.circle-ripple {
  width: 200px;
  height: 200px;
  border-radius: 50%;
  -webkit-animation: ripple 0.7s linear infinite;
  animation: ripple 1.5s linear infinite;
}

.appHolder {
  display: flex;
  justify-content: center;
  align-items: center;
}

@media (max-height:480px) {
  .appHolder {
      min-height: 90vh;
  }
}

.circle-ripple .profilePageDp {
  width: 200px;
  height: 200px;
  border: 2px solid #fff;
  object-fit: cover;
}

.w200px {
  width: 200px !important
}

.w250px {
  width: 250px !important
}

.input-range__label-container {
  display: none
}

.peopleViewHolder, .feedViewHolder {
  height: auto;
  width: 100%;
  border-radius: 5px;
  box-shadow: 0 1px 8px rgba(0, 0, 0, 0.15);
  margin: 10px auto 20px;
}

.peopleViewHolder {
  cursor: pointer;  
  max-width: 300px; 
}

/*.feedViewHolder {
  max-width: 280px;  
}*/

.BtoRcolor {
  color: #444 !important
}

.peopleSpaceHolder:hover .peopleViewHolder, .peopleSpaceHolder:hover .feedViewHolder {
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.15);
}

.peopleSpaceHolder:hover .BtoRcolor {
  color: #000000 !important; 
}

.mxWidth800 {
  max-width: 800px !important;
}

.mxWidth700 {
  max-width: 700px !important;
}

.mxWidth600 {
  max-width: 600px !important;
}

.peoplePic img {
  height: 150px;
  object-fit: cover;
  border-top-right-radius: 5px;
  border-top-left-radius: 5px;
  width: 100%;
}

.peopleShowHolder {
  flex-wrap: wrap;
  padding: 20px;
}

/* Feeds */
.feedViewHolder .peoplePic img {
  height: 180px;  
  object-fit: cover;      
  width: 100%;
  object-position: center 25%; 
  border-radius: 0px !important; 
}

.feedType { 
  position: absolute;
  top: 75px;
  text-align: center;
  left: 15px;
  right: 15px; 
  bottom: 82px;
  padding: 12% 0px;    
  color: #fff;
  font-weight: bold;
  text-transform: capitalize;
  background: rgba(0,0,0,0.2);
}

.feedType .text-truncate, .feedMsgContent .text-truncate {
  overflow: visible !important;
  word-wrap: break-word !important;
  word-break: break-word !important;  
  white-space: normal !important;   
}

.feedGenderIcon {
  background: url(../img/gender.png) center/cover;
  height: 15px; 
  width: 15px;
}

.feedProfileBody {
  padding: 10px 10px; 
  box-shadow: 0 7px 0px -6px #F5F7FA;
}

.feedProfileBody .currentUserDP {
/*  background: url(../img/profile.JPG) no-repeat scroll center center/cover;*/
  height: 40px;
  width: 40px; 
  border-radius: 50%;
  cursor: pointer; 
}

.feedMedia .currentLikeDP {
  background: url(../img/like.png) no-repeat scroll center center/cover;
  height: 40px;
  width: 40px; 
  border-radius: 50%;
  cursor: pointer; 
  border:none;
  background-color: transparent;
}

.feedMedia .currentLikeDP.active { 
  background: url(../img/liked.png) no-repeat scroll center center/cover !important;  
}

.feedMedia .currentChatDP { 
  background: url(../img/message_c.png) no-repeat scroll center 0%/ 100%;   
  height: 40px;
  width: 40px; 
  border-radius: 50%;
  cursor: pointer; 
  border: none !important;
  background-color: transparent !important;    
}


.feedMedia {
  position: absolute;
  right: 20px;
  bottom: 58px;   
}

.noFeedsContainers {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 85vh;
  margin: auto; 
}

.modal-dialog.matchPopup, .modal-dialog.boostPopup, .modal-dialog.superlikePopup {
  max-width: 400px !important; 
}

.superlikePopup .modal-header, .matchPopup .modal-header, .boostPopup .modal-header {   
  padding: 0px !important; 
} 

.matchlike {
    background: url(../img/like.png) center/cover;
    height:40px;
    width:40px;  
    position: absolute;
    left: 45%;
    top: 15%; 
}
.matchmedia {
  margin: 0 10px;  
}

.matchContent {
  font-size: 45px;
  color: #fff;
  font-weight: bold;
}

.matchContentSub {
    color: #fff;
    font-weight: bold;
} 

.matchButton {
  border: none;
  width: 100%;
  border-radius: 20px;
  padding: 5px 0;
  margin-top: 20px;
  font-weight: bold;
}

.matchmedia img {
  border: 5px solid #fff;
  height: 130px;
  width: 130px;
  border-radius: 100%;  
  object-fit: cover;
  object-position: center 25%;
}

.matchPopup .modal-images {
  padding: 0 8%; 
}

.matchPopup button.close, .superlikePopup button.close, .boostPopup button.close { 
  display: none !important; 
}

.matchPopup .modal-content, .boostPopup .modal-content, .superlikePopup .modal-content { 
  background-color: #9924ea !important;    
  border-radius: 25px !important; 
}

.boostPopup .boostPopupIcon {
  background: url(../img/boost_icon.png) center/cover; 
  height: 120px;
  width: 110px;
  object-fit: cover;
  object-position: center;
  margin: 20px auto 20px;
}

.superlikePopup .boostPopupIcon { 
  background: url(../img/superlike_icon.png) center/cover;  
  height: 120px;
  width: 110px;
  object-fit: cover;
  object-position: center;
  margin: 20px auto 20px;
}

.boostRemain {
  color: #ffffff;
  font-weight: 600;
  font-size: 30px;
}

.boostContent {
  color: #fff;
  font-weight: 600;
}

.boostPopup .modal-body. .superlikePopup .modal-body {
  padding: 0px !important; 
}

.feedMsgsLiked {
  background: url(../img/liked.png) center/cover; 
  height: 25px;
  width: 25px;
  position: absolute;
  top: 0px;
  left: 0px;
}

.feedMsgContent {
  position: absolute;
  left: 15px;
  top: 15px;
  right: 15px;
  bottom: 15px;
  border: none;
  border-radius: 8px; 
  background: rgba(0,0,0,0.3);
  color: #fff;
  font-weight: bold;
  text-align: center;
  padding: 0;   
  text-transform: capitalize;
}

.feedMsgContent > div:first-child {
  margin-top: 12%;        
}

/* Feeds End*/

@media (max-width:359px) {
  .col-Resp {
      flex: 0 0 100%;
      max-width: 100%;
  }
}

@media (min-width:1700px) {
  .col-xl-Resp {
      flex: 0 0 20%;
      max-width: 20%;
  }
}

col-xl-Resp .absPos {
  position: absolute;
}

.relPos {
  position: relative;
}

.searchRipplePos {
  position: absolute;
  bottom: -35px;
  width: 300px;
  text-align: center;
}

.searchRipplePos2 {
  position: absolute;
  bottom: -100px;
}

/*IN*/

.peopleINViewHolder {
  height: auto;
  max-width: 500px;
  border-radius: 5px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.15);
  cursor: pointer;
  margin: 10px auto 40px;
}

.peopleINPic img {
  height: 450px;
  max-width: 500px;
  width: 100%;
  object-fit: cover;
  border-top-right-radius: 5px;
  border-top-left-radius: 5px;
}

.peopleINPic {
  min-height: 450px;}

.threeDotMenuIcon {
  background: url(../img/option.png) no-repeat center/contain;
  height: 25px;
  width: 25px;
  cursor: pointer;
}

.phoneMICo {
  background: url(../img/call-answer.png) no-repeat center/contain;
  height: 23px;
  width: 23px;
  cursor: pointer;
}

.videoMICo {
  background: url(../img/facetime-button.png) no-repeat center/contain;
  height: 25px;
  width: 25px;
  cursor: pointer;
}

.circularMessageIco {
  background: url(../img/msg.png) center/cover;
  height: 25px;
  width: 25px;
  cursor: pointer;
}

.circularHorizontalDotIco {
  background: url(../img/cir-bar.png) no-repeat center/contain;
  height: 25px;
  width: 25px;
  cursor: pointer;
  margin-left: 2px;
  margin-top: 2px;
}

.circularIcoHolder {
  height: 50px;
  width: 50px;
  background: -webkit-linear-gradient(#000000, #000000);
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;
}

.circularIcoHolderMadjust {
  position: absolute;
  right: 0;
  top: -52px;
}

.interestsHolder .btn-border {
  margin: 5px 0px;
  padding: 2px 10px;
  border-radius: 15px;
  margin: 5px;
}

.RSoverride {
  background: none !important;
  border: none !important;
  box-shadow: none !important;
}

.dropDownRSoverride {
  will-change: unset !important;
  top: unset !important;
  left: unset; 
  transform: unset !important;
}

.reportDropPos {
  left: -110px; 
  top: 45px !important;
  padding: 0px !important; 
}

.upload-btn-wrapper {
  position: relative;
  overflow: hidden;
  display: inline-block;
}

.in-flex {
  display: inline-flex !important;
}

.boostPopup .premiumPop, .superlikePopup .premiumPop { 
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
  border-bottom-left-radius: 25px;
  border-bottom-right-radius: 25px; 
  margin-top: 50px;    
}

.ub-ul {   
    position: absolute;
    bottom: 0px;
    width: 100%;
}

.ubp-ul > li {
  width: 50%;
  padding: 10px 0; 
}

.ubp-ul > li > a {
  width: 100%; 
  cursor: auto; 
}

.ubp-ul > li button {
  cursor: pointer; 
  font-weight: 600;  
}

.ub-ul > li {
  width: 50%;
  background: #fff;
  padding: 10px;
}

.ub-ul > li > button {
  font-weight: 600;
  cursor: pointer;    
}

.ub-ul > li:first-child {
  border-bottom-left-radius: 25px;
}

.ub-ul > li:last-child { 
  border-bottom-right-radius: 25px; 
}

.boostActive {
  background: #fff;
  padding: 10px 0;
  border-bottom-left-radius: 25px;
  border-bottom-right-radius: 25px;
  font-weight: 600;
}

.boostPlans { 
  text-align: center;
  padding: 15px;
  font-weight: 600;
  font-size: 25px;
}
 
.imgUploader {
  background: #000000;
  border: none;
  border-radius: 16px;
  color: #fff;
  font-size: 14px;
  padding: 5px 15px;
  cursor: pointer;
}

.upload-btn-wrapper input[type=file] {
  font-size: 100px;
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
  cursor: pointer;
  height: 50px;
  width: 160px;
}

.initialCursor {
  cursor: initial !important;
}

.imgUploaderDropPos {
  top: -93px !important;
  left: -110px; 
  border-radius: 32px 32px 0px 32px;
  padding: 10px 25px;    
  box-shadow: 0 1px 8px rgba(0, 0, 0, 0.15);
}

.peopleNpage {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  min-height: 100vh;
}

/*People Slider*/

.peopleSlideDetails {
  position: absolute;
  width: 100%;
  bottom: 0px;
  z-index: 1;
  padding: 10px 0px;
  background: rgba(0, 0, 0, 0.2);
}

.likeButton {
  background: url(../img/like-64.png) center/cover;
  width: 60px;
  height: 60px;
  cursor: pointer;
  position: absolute;
  bottom: 84px;
  z-index: 1;
  left: 36%; 
}

.superlikeButton {
  background: url(../img/superlike-64.png) center/cover;
  width: 60px;
  height: 60px; 
  cursor: pointer;
  position: absolute;
  bottom: 84px;     
  z-index: 1;
  left: 50%;   
}

.peopleSlides .swiper-button-next.swiper-button-disabled,
.peopleSlides .swiper-button-prev.swiper-button-disabled {
  opacity: 1 !important;
}

.peopleSlides .swiper-button-next,
.peopleSlides .swiper-button-prev {
  position: absolute !important;
  top: unset;
  bottom: 80px !important;
  width: 45px !important;
  height: 45px !important; 
}

.peopleSlides .swiper-button-next {
  right: 25%;
  background: url(../img/next-64.png) center/cover !important;
}

.peopleSlides .swiper-button-prev {
  left: 24%; 
  background: url(../img/pri-64.png) center/cover !important;
}

@media (min-width:320px) and (max-width:400px) {
  .peopleSlides .swiper-button-next {
      right: 5vw;
  }

  .peopleSlides .swiper-button-prev {
      left: 5vw;
  }
}

@media (min-width:401px) and (max-width:499px) {
  .peopleSlides .swiper-button-next {
      right: 10vw; 
  }

  .peopleSlides .swiper-button-prev {
      left: 10vw;
  }
}

@media (min-width:320px) and (max-width:340px) {
  .likeButton {
      left: 25vw;
  }
}

@media (min-width:341px) and (max-width:360px) {
  .likeButton {
      left: 25vw;    
  }
}

@media (min-width:361px) and (max-width:499px) {
  .likeButton {
      left: 30vw;    
  } 
}

/*People Slider*/

/*Help Page*/

.helpLeftMenu {
  flex: 0 0 auto;
  width: 200px;
  overflow-y: auto;
  height: 300px;
  position: sticky;
  top: 20px;
}

.helpRightMenu {
  flex: 1 1 auto;
  overflow-y: auto;
}

.helpLeftNav div {
  padding-bottom: 15px;
  font-size: 16px;
  cursor: pointer;
}

.helpLeftNav a.active {
  color: #000000;
  font-family: 'QanelasSoft-Bold';
}

.helpRightMenu .dropdown-toggle::after {
  display: inline-block;
}

.helpLinkButton {
  background: none !important;
  border: none !important;
  color: #444 !important;
  box-shadow: none !important;
}

.stickypos {
  position: sticky !important;
  width: 100%;
  top: 0;
  z-index: 2;
}

.helpLinkDropDown {
  left: -40px !important
}

/*Help Page*/

/*Message Page*/
/* 
.messageSection {
  padding: 0px 10px;
} */

.messageSpace {
  width: 100%;
  /* margin: 10px auto; */
  box-shadow: 0 0px 3px rgba(0, 0, 0, 0.15);
}

.messagess .backGlow {
  padding: 0px
}

.messagess {
  border-bottom: 1px solid #ececec;
  padding: 15px 15px;
}

.messages-flex {
  align-self: center;
  overflow: hidden;
}

.messages-overview {
  font-size: 14px;
  width: 100%;
  cursor: pointer;
}

.message-profile {
  height: 60px;
  width: 60px;
  border-radius: 100%;
  object-fit: cover;
  cursor: pointer;
}

.fav-notifier {
  background: url("../img/fav-p.png") center/cover;
  height: 30px;
  width: 30px;
  cursor: pointer;
}

.fav-notifier.Star {
  background: url("../img/fav.png") center/cover;
  height: 40px;
  width: 40px;
  cursor: pointer;
}

.mChatbox {
  overflow-y: auto;
  overflow-x: hidden;
  margin-bottom: auto;
}

.messageSpaceH {
  height: 97vh !important;
  display: flex;
  /*justify-content: space-between;*/
  flex-direction: column;
}

.mLeftChat {
  background: #e1e8eb;
  min-height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 600px;
  border-radius: 20px 20px 20px 0px;
  position: relative;
  line-height: 20px;
  font-size: 16px;
  word-break: break-all;
}

.mRightChat {
  background: #E2E2E2;
  min-height: 40px;
  color: black;
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 600px;
  border-radius: 20px 20px 0px 20px;  
  position: relative;
  line-height: 20px;
  font-size: 16px;
  word-break: break-all;
}

.mMessageInputs {
  border-top: 1px solid #ececec;
  padding: 10px 20px;
}

.mMessageInputs textarea {
  line-height: 23px;
  font-size: 16px;
  overflow: hidden;
  resize: none;
  white-space: nowrap;
  height: 40px;
  padding: 10px;
}

.attachment-file {
  background: url(../img/attach.png) no-repeat right/contain;
  width: 25px;
  height: 25px;
  cursor: pointer;
}

.send-option {
  background: url(../img/send.png) no-repeat right/contain transparent;
  width: 32px;
  height: 32px;
  cursor: pointer;
}

.mMessageInputs input[type="file"] {
  display: none;
}

.messageDropdown {
  padding: 5px 15px
}

.messagess .reportDropPos {
  left: -120px; 
}

.messageFlex {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

.messagee {
  padding: 10px 5px 10px 5px !important;
}

/*Message Page*/

@media (min-width:1400px) {
  .leftSideMenu {
      width: 360px
  }

  .ProfileSpace {
      max-width: 600px;
  }

  .circle-ripple .profilePageDp {
      width: 200px;
      height: 200px;
  }

  .circle-ripple {
      width: 200px;
      height: 200px;
  }

  .peoplePic img {
      height: 200px;
  }

  .peopleINViewHolder {
      max-width: 700px !important;
  }

  .peopleNpage .peopleINViewHolder {
      max-width: 900px !important;
  }

  .ProfileSpace {
      max-width: 700px !important;
  }

  .peopleNpage .ProfileSpace {
      max-width: 900px !important;
  }

  .peopleINPic img {
      max-width: 700px !important;
      height: 700px;
  }
  .peopleINPic {
    min-height: 700px;}

  .peopleNpage .peopleINPic img {
      max-width: 900px !important;
      height: 700px;
  }

  .peopleNpage .peopleINPic img {
    max-width: 900px !important;
    height: 700px;
}
.peopleNpage .peopleINPic  {
  min-height: 700px;
}

  .likeButton {
      left: 45.4%;
  }

  .peopleNpage .likeButton {
    left: 42.5%;
  }

  .peopleSlides .swiper-button-next {
      right: 34%;
  }

  .peopleSlides .swiper-button-prev {
      left: 34%;
  }

  .text-truncate.bold-font.apk-18.text-light.apl10.apr10 {
      font-size: 25px !important
  }

  .peopleSlides .swiper-button-next,
  .peopleSlides .swiper-button-prev,
  .likeButton {
      bottom: 85px !important;
  }
}

/*welcome-page*/

.welcomePage {
  background: url(../img/howzu.png) no-repeat top center/cover #000000;
  min-height: 100vh;
  min-height: 100vh;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.welcomePageCenterContent {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 600px;
  width: 100%;
  padding: 25px 20px;
}

.welcomeLogo {
  background: url(../img/welcome-logo.png) no-repeat center/contain;
  height: 150px;
  width: 200px;
}

.btnsWelcome {
  padding: 10px 40px;
  width: 100%;
  text-align: center;
  margin: 10px 0px;
  background: transparent;
}
.btnsWelcome:active {
  transform: scaleY(2px)
}



#welcome .carousel-indicators li {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: #fff;
}

#welcome .carousel-indicators .active {
  background-color: transparent;
  border: 2px solid #fff;
  margin-top: 10px !important;
  height: 6px !important;
  width: 6px !important;
}

/*welcome-page*/

/*Signin Page*/

.signInPage {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  justify-content: space-between;
}

.signInPage .commonLogoC {
  width: 150px !important;
}

.signInForms {
  max-width: 500px;
  margin-top: 40px;
  width: 100%;
  padding: 10px;
}

.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
  color: inherit;
  border-bottom: 2px solid #000000 !important;
  border: none;
  color: #000000;
}

.nav-tabs .nav-link:focus,
.nav-tabs .nav-link:hover {
  border-color: transparent;
}

.footerS {
  padding: 15px 0px;
  position: fixed;
  width: 100%;
  bottom: 0;
  height: auto;
  background: #ffffff;
  word-wrap: break-word;
}

.welcomeHeader {
  position: sticky;
  top: 0;
  z-index: 2;
}

.textBtn {  
  border: none;
  background: none;
  padding: 0;
  cursor: pointer; 
}

.likeButton2 {
  background: url(../img/like-64.png) center/cover;
  width: 60px;
  height: 60px; 
}

.superlikeButton2 { 
  background: url(../img/superlike-64.png) center/cover;
  width: 60px; 
  height: 60px;
}

.dislikeButton {
  background: url(../img/next-64.png) center/cover;
  width: 60px;
  height: 60px;
}

.changeLoc {
  border: none;
  border-bottom: 2px solid #ececec;
  height: 45px;
}

/* .help-bg .nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
  border: none !important
} */
.help-leftpart  .nav-tabs .nav-link.active {
  border: none !important
} 

.setFiltBox {
  max-width: 600px;
  box-shadow: 0 1px 8px rgba(0, 0, 0, 0.15);
  margin: 0 auto;
  width: 100%;
  padding: 30px;
}

@media(min-width:1400px) {
  .setFiltBox {
      max-width: 800px;
  }
}

/*Premium Popup*/

.premiumPopup .close {
  background: url("../img/close-v.png") no-repeat 0 0/cover !important;
  height: 15px;
  width: 15px;
  padding: 0 !important;
  margin: 10px;
  opacity: 1 !important;
}

.premiumPopup .modal-header {
  border: none !important;
  padding: 10px 20px 0px 30px;
}

.premiumPopup .modal-content {
  border-radius: 25px !important; 
}

.premiumPopup .close span {
  display: none;
}

.premiumPop {
  background: #fff;
}

.premiumImgHolder {
  margin: 0 auto;
  width: 100%;
  max-width: 250px;
  display: flex;
  justify-content: center;
}

.premiumImgHolder img {
  height: 200px;
  object-fit: cover;
}

.premiumPrice {
  min-width: 100px;
  text-align: center;
  border: 2px solid #e1e8eb;
  border-radius: 15px;
  cursor: pointer;
  margin: 5px;
}

.mini-price {
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
}

.premiumPopPlans .navigation--active .mini-price {
      background-color: #000000;
/*  background: -webkit-linear-gradient(#000000, #000000);*/
  color: #fff;
  font-family: 'QanelasSoft-Medium';
}

.premiumPopPlans .navigation--active .daysPrice {
      background-color: #E2E2E2;
/*  background: -webkit-linear-gradient(#000000, #000000);*/
  color: #fff;
  border-radius: 10px 10px 0px 0px;
}

.premiumPopPlans .navigation--active .premiumPrice {
  border: 4px solid #000000;
}

.premiumInfoHolder {
  text-align: center;
  margin: 30px 0px 5px; 
  min-height: 80px;  
}

.premiumPopup .swiper-container-horizontal>.swiper-pagination-bullets,
.swiper-pagination-custom,
.swiper-pagination-fraction {
  bottom: 105px; 
}

.premiumPopup .sidebarLastContent {
  padding: 15px 15px 0px;
}

.premiumPopup.superlikePopup .sidebarLastContent {
  padding: 15px !important;  
}

@media(max-height:600px) {
  .premiumImgs {
      display: none;
  }
}

@media(min-height:800px) {

  /* .premiumPopup {
      max-width: 700px;
  } */

  .premiumImgHolder img {
      height: 300px;
  }

  .premiumPopup .swiper-container-horizontal>.swiper-pagination-bullets,
  .swiper-pagination-custom,
  .swiper-pagination-fraction {
      bottom: 130px;
  }
}

@media (max-width:450px) {  

  .premiumPopup .swiper-container-horizontal>.swiper-pagination-bullets,
  .swiper-pagination-custom,
  .swiper-pagination-fraction {
      bottom: 110px;        
  }
}

/*App of the Day Popup*/

.appofDay .swiper-container-horizontal>.swiper-pagination-bullets,
.swiper-pagination-custom,
.swiper-pagination-fraction {
  bottom: 0;
}

.appofDay .modal-header {
  padding: 10px 20px 10px 30px;
}

.appofDay .modal-body {
  padding: 0px;
}

.appofDay .appofDayImg img {
  width: 100%;
  height: 200px;
  object-fit: cover;
}

.appodDayoverflow {
  padding: 0px 15px;
  height: 100%;
  max-height: 250px;
  overflow-y: auto;
}

.appofDayTitle {
  /*position: sticky;*/
  top: 0;
  background: #fff;
  text-align: center;
}

.appofDay .sidebarLastContent {
  padding: 15px 15px;
}

.appofDay .premiumPop {
  border-bottom-left-radius: 32px;
  border-bottom-right-radius: 32px;
}

@media(min-height:900px) {
  .appofDay .appofDayImg img {
      height: 400px;
  }

  .appodDayoverflow {
      height: 100%;
      max-height: 350px;
      overflow-y: auto;
  }
}

.signinPagePop .sidebarLastContent {
  padding: 15px 0px;
}

.signinPagePop {
  max-width: 500px;
  ;
}

.centerImgSelecting {
  position: absolute;
  left: 0;
  right: 0;
  margin: auto
}


/*new*/
.slick-dots {
  bottom: 25px;
}

.slick-dots li {
  margin: 0;
  width: 15px;
}

.slick-dots li.slick-active button::before {
  opacity: 1;
  color: #000000;
}

.photoLoader {
  margin: 0px 20px; 
}

.slick-dots li button::before {
  font-size: 8px;
}
 
.errorMsg {
  opacity: 1;
  font-size: 0.7em;
  color: red;
  text-transform: uppercase;  
}

.errorMsg .floatingAlerts1 {
  border: 1px solid red !important;  
}

.successMsg .floatingAlerts1 {   
  border: 1px solid -webkit-linear-gradient(#000000, #000000) !important;
}

.successMsg {
  opacity: 1;
  font-weight: bold;  
  font-size: 0.7em;
  color: #000000;    
  text-transform: uppercase;
  transition: all 250ms linear;
}

.successMsghide {
  display: none;
  transition: all 250ms linear;
}

.errorMsghide {
  display: none;
  transition: all 250ms linear;
}

.floatingAlerts1 {
  font-size: 16px;
  position: absolute;
  top: 80px;
  right: 0;
  background: #ffffff;
  padding: 10px 30px; 
  border-radius: 16px;
  z-index: 10;
  box-shadow: 0 0px 4px rgba(0, 0, 0, 0.15);
}

.noItemsContainers {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 70vh;  
}

.nullSize {
  height: 130px;
  width: 180px;
  background-repeat: no-repeat;
  background-size: contain;
}

.nullFriends {
  background-image: url(../img/no-friends.png);
}

.nullFriendsRequest {
  background-image: url(../img/no-request.png);
}

.nullMessage {
  background-image: url(../img/no-message.png);
}

.nullVisitors {
  background-image: url(../img/no-visiters-premium.png);
}

.cls_bt button.close {
  display: none
}

.topHeaderDrops {
  color: #000000 !important;
  -webkit-appearance: none;
  cursor: pointer;
  background: url("../img/down-arrow.png") no-repeat right center/10px;   
}

.topHeaderDrops option {
  color: #444444;
}


.becomePremiumVisitorPage~.peopleShowHolder .peoplePic {
  -webkit-filter: blur(5px);
  filter: blur(5px);
}

.becomePremiumVisitorPage~.peopleShowHolder .text-truncate.secondaryText.esmh-14.regular-font,
.becomePremiumVisitorPage~.peopleShowHolder .ml-1 {
  -webkit-filter: blur(5px);
  filter: blur(5px);
}

.becomePremiumVisitorPagee~.peopleShowHolder .peoplePic {
  /* -webkit-filter: blur(5px);
  filter: blur(5px); */
}

.becomePremiumVisitorPagee~.peopleShowHolder .text-truncate.secondaryText.esmh-14.regular-font,
.becomePremiumVisitorPage~.peopleShowHolder .ml-1 {
  /* -webkit-filter: blur(5px);
  filter: blur(5px); */
}

/* .becomePremiumVisitorPage ~ .peopleShowHolder{
  pointer-events: none;
} */
.premiumCurve {
  width: 100%;
  height: 100px;
  border: solid 5px -webkit-linear-gradient(#000000, #000000);
  border-color: #000000 transparent transparent transparent;
  border-radius: 50%/100px 100px 0 0;
}

.likedS {
  position: absolute;
  top: 50px;
  z-index: 1;
  left: 20px;
  border: 2px solid skyblue;
  border-radius: 5px;
  padding: 5px 20px;
  color: skyblue;
  font-size: 20px;
  font-family: 'QanelasSoft-Bold';
  transform: rotate(-35deg);
}

.disLikedNope {
  position: absolute;
  top: 50px;
  z-index: 1;
  right: 20px;
  border: 2px solid red;
  border-radius: 5px;
  padding: 5px 20px;
  color: red;
  font-size: 20px;
  font-family: 'QanelasSoft-Bold';
  transform: rotate(35deg);
  -moz-animation: cssAnimation 0s ease-in 1s forwards;
  /* Firefox */
  -webkit-animation: cssAnimation 0s ease-in 1s forwards;
  /* Safari and Chrome */
  -o-animation: cssAnimation 0s ease-in 1s forwards;
  /* Opera */
  animation: cssAnimation 0s ease-in 1s forwards;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
}

.hideMe {
  -moz-animation: cssAnimation 0s ease-in 4s forwards;
  /* Firefox */
  -webkit-animation: cssAnimation 0s ease-in 4s forwards;
  /* Safari and Chrome */
  -o-animation: cssAnimation 0s ease-in 4s forwards;
  /* Opera */
  animation: cssAnimation 0s ease-in 4s forwards;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
}

@keyframes cssAnimation {
  to {
      width: 0;
      height: 0;
      overflow: hidden;
      border: 0;
  }
}

@-webkit-keyframes cssAnimation {
  to {
      width: 0;
      height: 0;
      visibility: hidden;
      border: 0;
  }
}

.messageImgs {
  border-radius: 20px;
  /*height: 200px;*/
  width: 230px; 
  background-size: cover;
}

.messageImgs img  {
  border-radius: 8px !important; 
  object-fit: cover;
  object-position: center;
  min-height: 150px; 
  max-height: 150px;     
}

.blinking {
  animation: blinkingText 1s infinite;
}

.blinking1 {
  animation: blinkingText 1.2s infinite;
}

.blinking2 {
  animation: blinkingText 1.3s infinite;
}

.loadingTxts {
  /*-moz-animation: cssAnimation 0s ease-in 2s forwards;
  *//* Firefox */
  /*-webkit-animation: cssAnimation 0s ease-in 2s forwards;
  *//* Safari and Chrome */
  /*-o-animation: cssAnimation 0s ease-in 2s forwards;
  *//* Opera */
  /*animation: cssAnimation 0s ease-in 2s forwards;
  */-webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  color: #000000;
  text-align: center;
  padding: 2px;
  font-family: 'QanelasSoft-Bold';

}

@keyframes blinkingText {
  0% {
      color: #000;
  }

  49% {
      color: transparent;
  }

  50% {
      color: transparent;
  }

  99% {
      color: transparent;
  }

  100% {
      color: #000;
  }
}

/*Premium Benifits*/
.premiumBenifit .modal-header .modal-title {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.premiumBenifit .modal-header,
.premiumBenifit .modal-body {
  border-bottom: none;
  padding: 0px;
}

.premiumBenifit .modal-content {
  border-radius: 32px !important;
}

.premiumBenifit button.close {
  display: none;
}


.premiumBDP {
  height: 80px;
  width: 80px;
  border-radius: 50%;
  cursor: pointer;
  object-fit: cover;
}

.amtN45 {
  margin-top: -45px;
}

.preIcon {
  background: url(../img/batch-32.png) no-repeat center/contain;
  height: 25px;
  width: 25px;
  cursor: pointer;
  position: absolute;
  bottom: -3px;
  right: -3px;
}

.preIcon2 {
  background: url(../img/batch-32.png) no-repeat center/contain;
  height: 40px;
  width: 40px;
  cursor: pointer;
  position: absolute;
  bottom: -12px;
  right: 80px;
}

.tickIco {
  background: url(../img/tick.png) no-repeat center/contain;
  height: 25px;
  width: 25px;
  align-self: center;
}

.premiumBenifitOverflow {
  height: 100%;
  max-height: 300px;
  overflow-y: auto;
  overflow-x: hidden;
  padding: 0.5rem 1rem;
}

.blockedInfo {
  padding: 20px;
  text-align: center;
  color: red;
  position: sticky;
  top: -15px;
  background: #fff;
  z-index: 1;
}

.buybutton {
  display: none;
}

.tempload {
  display: block;
  transition-delay: '20s';   
  align-self: center;
}

.buybuttonshow {
  transition-delay: '20s';
  background: -webkit-linear-gradient(#000000, #000000) !important;
  color: #fff !important;
  font-size: 14px;
  border: none;
  border-radius: 30px;
  padding: 10px 15px;
  width: 300px;
  align-self: center;
}

.breakall {
  word-break: break-all;
}

.preIco2 {
  background: url(../img/batch.png) no-repeat center/contain;
  height: 40px;
  width: 40px;
  cursor: pointer;
  position: absolute;
  bottom: -15px;
  left: 50%;
  -webkit-transform: translate(-50%, 0%);
  transform: translate(-50%, 0%);
}

.buttondelay {
  transition-delay: 10s;
}


.apd5Imp {
  padding: 1rem !important;
}

.peoplePic {
  position: relative;
  min-height:150px;
  background: #fff;
}

.onlineN {
  position: absolute;
  height: 15px;
  width: 15px;
  background: green;
  border-radius: 10px;
  right: 10px;
  bottom: -8px;
  border: 2px solid #fff;
}

.onlineN2 {
  position: absolute;
  height: 15px;
  width: 15px;
  background: green;
  border-radius: 10px;
  right: 0px;
  bottom: 0px;
  border: 2px solid #fff;
}

.justNowN {
  position: absolute;
  height: 15px;
  width: 15px;
  background: orange;
  border-radius: 10px;
  right: 10px;
  bottom: -8px;
  border: 2px solid #fff;
}

.searchMessage input {
  border: 1px solid #ececec;
  margin-right: 5px;
  padding: 10px;
  background-image: url("../img/search.png");
  background-size: 22px;
  background-repeat: no-repeat;
  background-position: 10px;
  padding-left: 50px;
  width: 100%;
}

.adMob {
  padding: 10px 0px;
  /*position: sticky;*/ 
  bottom: 0;
  background: #fff;
  text-align: center; 
}

.ht-fix {
  height: 90vh
}

.msgTimerFxRight {
  position: absolute;
  bottom: -20px;
  right: 0px;
  color: #000;
  font-size: 12px;
  min-width: 150px;
  text-align: right;
  text-transform: capitalize; 
}

.msgTimerFxleft {
  position: absolute;
  bottom: -20px;
  left: 0px;
  color: #000;
  font-size: 12px;
  min-width: 150px;
  text-align: left;
  text-transform: capitalize;   
}

.loadMor {
  width: 150px;
  margin: 10px auto 30px;
  padding: 10px;
  text-align: center;
  background: -webkit-linear-gradient(#000000, #000000);
}

.btn-common {
  background: -webkit-linear-gradient(#000000, #000000) !important;
  color: #fff !important;
  font-size: 14px;
  border: none;
  border-radius: 30px;
  padding: 10px 30px;
  cursor: pointer;
  text-align: center
}

.google-covert {
  position: absolute;
  background: #fff;
  padding: 0px 15px;
  border: none;
  max-height: 200px;
  overflow-y: auto;
}

.google-covert>li {
  padding: 10px 0px;
  list-style: none;
  position: sticky;
  top: 0;
  background: #fff;
  border-bottom: 1px solid #ececec;
}

.google-covert .style-unordered-list li {
  padding: 8px 0px 0px 27px;
  cursor: pointer;
  user-select: none;
  -moz-user-select: none;
}

.custom-style-list {
  cursor: pointer;
}

.pre21 {
  overflow-x: auto;
  white-space: pre-wrap;
  white-space: -moz-pre-wrap;
  white-space: -pre-wrap;
  white-space: -o-pre-wrap;
  word-wrap: break-word;
}


.modal-body .modal-body {
  padding: 5px 15px;
}

.current-loc-ico {
  height: 25px;
  padding-right: 10px;
  margin-top: -3px;
}

.braintree-sheet__header {
  border-bottom: 1px solid #ececec;
}

.braintree-sheet {
  border: 1px solid #ececec;
  box-shadow: 0 1px 8px rgba(0, 0, 0, 0.15);
}

.braintree-sheet__content--form .braintree-form__field-group .braintree-form__field .braintree-form__hosted-field {
  border: 1px solid #ececec;
}

.braintree-sheet__content--form .braintree-form__field-group .braintree-form__field .braintree-form__hosted-field:hover {
  border-color: #f5f5ef;
}

.setting-page.peopleNpage .appHolder{
height: 90vh;
}

input{
box-shadow: none !important;
outline: none !important;
}
.imgW{
  width:140px;
}
.cardMin{
  min-height: 65px;
}

.blueBannerSignIn{
  background: skyblue;
  height: 240px;
  border-top-right-radius: 24px;
  border-top-left-radius: 24px;
} 

.upBtn{
  background: #ff346f;
color: #fff;
border: none;
padding: 5px;
margin-left: 5px;
border-radius: 30px;
padding: 4px 10px;
cursor: pointer;
}
.img-blur{
-webkit-filter: blur(5px); /* Safari 6.0 - 9.0 */
filter: blur(5px);
}
.notfound-txt{
    display: flex;
    flex-direction: column;
    height: 100vh;
    justify-content: center;
    align-items: center;
  }
.addphoto
{
margin-right:10px;
color:#fff;
font-weight:bold;
font-size:25px;
}
.addphoto label
{
margin-bottom:unset !important;
}
.textcapfirst
{
text-transform: capitalize;
}
.chatimgdiv
{
  width: 100%;
  height: 100%;
}
.signininterest
{
  max-height: 500px;
  overflow-y: scroll;
}

html {
scrollbar-width: thin;
}

* {
scrollbar-width: thin;
}

::-webkit-scrollbar {
width: 8px;
}

::-webkit-scrollbar-track {
background: rgb(238, 238, 238);
}

::-webkit-scrollbar-thumb {
background: rgba(0, 0, 0, 0.25) !important;
}

 .appofDay .modal-body
 {
 border-bottom-left-radius: 32px;
border-bottom-right-radius: 32px;

 }
 .premiumPop .wholecont
 {
  max-height: 500px;
  overflow-y: auto;
 }


 .appofDay.vertically-center
 {
  height: 100% !important;
  width:unset !important;
  vertical-align: middle;
 }

 .appofDay .premiumPop {
    border-bottom-left-radius: 32px;
    border-bottom-right-radius: 32px;
  }

  .searchvalue .input-range__slider
  {
    top:2px !important;
  }

  .input-range__slider-container {
transition: left 0.1s ease-out !important;
}

.input-range__track {
transition: left 0.1s ease-out, width 0.1s ease-out !important;
}
.checkdisp
{
  display: block !important;
}
.helpcont
{
  overflow-y: auto;
  height: 90vh; 
  padding: 0px 15px;   
}

/* otpHolder */
.otp-cnt {
  padding: 0 7%; 
}

.otp-modal-title {
  text-transform: capitalize;
  color: #000000;
  font-weight: bold;
  margin: 0;
  font-size: 1.3rem;

}

.otp-sub-title {
  margin-bottom: 30px;
  font-size: 16px;
  font-weight: bold;
}

.otp-cnt .inputStyle {  
  width: 3rem !important;
  height: 3rem;
  margin: 0 1rem;
  font-size: 1.7rem;
  border-radius: 4px;
  border: 2px solid #000000;
  color: #444444; 
}

.otp-cnt span {
  color: #000000;
  font-weight: bold;
  font-size: 1.7rem;
}

@media (min-width:320px) and (max-width:340px) {
  .otp-cnt .inputStyle {
      width: 40px !important;
      margin: 0px !important;
  }
  .otpHolder .btn-row .clrBtn, .otpHolder .btn-row .vryBtn {
    margin: 0px 10px !important;  
  }
}

@media (min-width:341px) and (max-width:360px) {
  .otp-cnt .inputStyle {
      width: 45px !important; 
      margin: 0px !important;
  }
  .otpHolder .btn-row .clrBtn, .otpHolder .btn-row .vryBtn {
    margin: 0px 15px !important;   
  }
}

@media (min-width:361px) and (max-width:400px) {
  .otp-cnt .inputStyle {
      width: 40px !important; 
      margin: 0px 5px !important;  
  }
  .otpHolder .btn-row .clrBtn, .otpHolder .btn-row .vryBtn {
    margin: 0px 25px !important;    
  } 
}

@media (min-width:401px) and (max-width:450px) {
  .otp-cnt .inputStyle {
      margin: 0px 5px !important;  
  }  
}

@media (min-width:451px) and (max-width:500px) {
  .otp-cnt .inputStyle {
      margin: 0px 12px !important;   
  }  
}

.otpHolder .btn-row {
  margin-top: 30px; 
}
  
.otpHolder .btn-row .clrBtn, .otpHolder .btn-row .vryBtn {
  border: 1px solid #000000;
  background: -webkit-linear-gradient(#000000, #000000);
  margin: 0 30px;
  border-radius: 50px;
  padding: 5px 20px;
  color: #fff;
  font-weight: bold;
}

.otpHolder .errorMsg {  
  opacity: 1;
  font-size: 14px !important; 
  text-transform: uppercase;
  font-weight: bold !important;
  text-align: center !important;
  margin-top: 20px !important;
}


.welcomePage .css-yk16xz-control, .welcomePage .css-1pahdxg-control { 
  background-color: transparent !important;
  border: 1px solid #ffffff !important;  
}

.welcomePage .App-Select .css-1uccc91-singleValue {
  color:#ffffff !important;  
  font-weight:bold !important; 
}

.welcomePage .App-Select .css-1okebmr-indicatorSeparator, .welcomePage .App-Select .css-tlfecz-indicatorContainer, .welcomePage .App-Select .css-1gtu0rj-indicatorContainer:hover { 
  color: #ffffff !important;
}        


.premiumPop .premiumPopPlans .swiper-button-next, .premiumPop .premiumPopPlans .swiper-button-prev { 
  /* border: none;
  border-radius: 100%;
  background-color: rgba(0,0,0,0.2);
  height: 30px;
  width: 30px;
  background-size: 50%;  */
  border: none;
  border-radius: 100%;
  background-color: rgba(0,0,0,.0);
  height: 30px;
  width: 30px;
  background-size: 30%;
  box-shadow: 0 1px 3px rgba(0,0,0,0.15);
  background-color: #fff

} 
   
.premiumPop .premiumPopPlans .swiper-button-next {
  right: 2px !important;
  left: auto !important; 
} 

.premiumPop .premiumPopPlans .swiper-button-prev {  
  left: 2px !important;
  right: auto !important;      
}

.swiper-slide.navigation--active .premiumPrice .apk-24 { 
  font-size: 20px !important;     
}

.sidebarWrap .App-Select {  
  margin-top: 20px !important;   
}

/* 16.07.2020 */
.setting-page.messageFlex .messagee .media .mx-3{
  margin-left: 0 !important;
}
.profilePageDp, .currentUserDP {
  object-fit: cover;
}
.edit-profile-page .checkmark {
  top: 3px !important;
}
.edit-profile-page .radio-btn {
  padding-left: 30px !important;
  color: #888;
}
/*.backGlow {
  margin-left: 10px;   
} */ 
                                                                        
.send-option {
  width: 38px !important;
  height: 38px !important;
}
.profile-header-top {
  padding: 5px 20px !important;
}
/* UI Design team review styles */

.reportDropPos a .text-center { 
  text-align: center !important;  
  padding: 0 10px 5px 10px !important; 
}

.reportDropPos button {
  cursor: pointer; 
  padding: 8px !important; 
}

.reportDropPos button + button {   
  border-top: 1px solid rgba(0,0,0,0.05) !important;  
}

.reportDropPos button.text-center {
  text-align: center !important;    
  padding: 8px !important;   
}

.dropDownRSoverride button .text-center {
  text-align: center !important;    
  padding: 0px !important;  
}

.sideLogoSection .sideLogo, .editProfileIcon img {
  background-color: transparent !important;
}

.topHeaderDrops {
  width: 165px !important;   
  background-position: 100% 10px;
  background-size: 13px !important;
  padding-right: 2rem;  
  padding-left: 0px; 
  margin: 0px !important;   
}
.matchlike {
  height: 50px !important;
  width: 50px !important;
  left: 0 !important;
  top: 15% !important;
  right: 0 !important;
  margin: 0 auto;
}
.matchPopup .matchContentSub {
  font-weight: unset !important;
}
.matchPopup .matchButton {
  padding: 7px 0 !important;
  font-weight: unset !important;
  color: #444 !important;
}
.matchPopup .modal-content {
  background: linear-gradient(180deg, rgb(148, 28, 187) 0%, rgb(148, 28, 187) 5%, rgb(47, 50, 181) 100%) !important;
  border: none !important;
}
.premiumPrice {
  color: #888;
}
.premiumPopPlans ul li button {
  font-weight: unset !important;
}
.setFiltBox .searchvalue {
  padding-left: 0 !important;
}   
  

.edit-profile-page .radio-btn input:checked ~ .gender {
    color: #000000;
}

.edit-profile-page .react-date-picker {
  display: block !important; 
  border: none !important; 
  border-bottom:  1px solid #e1e8eb !important;   
}

.edit-profile-page .react-date-picker__wrapper {
  border: none !important; 
}

.sidebarWrap .App-Select .css-yk16xz-control {
  max-width: 195px !important;  
}
/*********** Custom style for location **************/
.pac-target-input:focus{
  border-color: #000000;
  border-width: medium medium 2px;
}
.pac-target-input{
  padding: 5px;
  border: none;
  width: 100%;
  border-bottom: 1px solid #e1e8eb;
  height: 30px;
}
#inputText:focus{
  border-color: #000000;
  border-width: medium medium 2px;
}
.pac-container{
  z-index: 99999;
}
.subscribeprice {
  display: flex;
  justify-content: center;
  align-items: center;
}
.activated{
  color:green !important;
  text-transform: capitalize;
}
.expired{
  color:red !important;
  text-transform: capitalize;
}